import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { ReplaySubject } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';

export interface ToasterNotification {
  toDisplay?: ComponentPortal<any> | string;
  backgroundColor?: string;
}

@Injectable({ providedIn: 'root' })
export class ToasterService {
  private readonly _notification: ReplaySubject<ToasterNotification> = new ReplaySubject<ToasterNotification>();

  private readonly _clearToast: ReplaySubject<void> = new ReplaySubject<void>();

  get notification(): ReplaySubject<ToasterNotification> {
    return this._notification;
  }

  get clearToast(): ReplaySubject<void> {
    return this._clearToast;
  }

  open(component: ComponentType<any> | string, config: { backgroundColor?: string }, timeDisplayed?: number): void {
    this._notification.next({
      toDisplay: typeof component === 'string' ? component : new ComponentPortal(component),
      backgroundColor: config.backgroundColor,
    });
    if (timeDisplayed) {
      setTimeout(() => this.clear(), timeDisplayed);
    }
  }

  clear(): void {
    this._clearToast.next();
  }
}
