export enum FeaturesRoutingEnum {
  Accessibility = 'accessibilite',
  Article = 'articles',
  Contact = 'contact',
  CGU = 'cgu',
  Events = 'evenements',
  Formation = 'formations',
  Jobs = 'emplois',
  LegalMentions = 'mentions-legales',
  Privacy = 'confidentialite',
  Services = 'services',
  NotFound = '404',

  Drive = 'wizbii-drive',
  Themes = 'thematics',
  Theme = 'thematics/:themeId',
  Lessons = 'lessons',
  Lesson = 'lesson',
  LessonId = ':lessonId',
  Chapters = 'chapters',
  Chapter = 'chapters/:chapterId',
  Series = 'series',
  Serie = ':type/:mode/:serieId',
  Progress = 'progress',
  Play = 'play',
  Advices = 'advices',
  Advice = 'advices/:articleSlug',
  Result = 'result/:serieId',
  Resume = 'resume',

  Money = 'wizbii-money',
  Simulation = 'simulation',
  Offers = 'offers',
  Infos = 'infos',
  Helps = 'aides',
  Blog = 'blog',

  Signup = 'sign-up',
  Signin = 'sign-in',
  User = 'user',
  Unsubscribe = 'unsubscribe',

  Presentation = 'presentation',
}
