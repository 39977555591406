import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatRippleModule } from '@angular/material/core';
import { MatCalendar, MatCalendarCellClassFunction, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectivityInterface } from '@b2b/models';
import { EventCalendarCardModule } from '@domains/events/events-agenda/event-calendar-card/event-calendar-card.component';
import { EventsCalendarHeaderComponent } from '@domains/events/events-agenda/events-calendar/events-calendar-header/events-calendar-header.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { algoliaEventToCommonListEvent, CommonListEvent } from '@models/common-event';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { CitiesState } from '@stores/cities/cities.state';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { EventWebservice } from '@webservices/event/event.webservice';
import { City } from '@wizbii/models';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, startWith, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsCalendarComponent implements OnInit, OnDestroy {
  @Output() closeCalendar = new EventEmitter<boolean>();

  selectedDate$ = new BehaviorSubject<Date>(new Date());
  events$ = new BehaviorSubject<CommonListEvent[]>([]);
  currentEvents$ = new BehaviorSubject<CommonListEvent[]>([]);
  dates$ = new BehaviorSubject<Date[] | undefined>(undefined);
  city$!: Observable<City | undefined>;

  @ViewChild('calendar') calendar!: MatCalendar<Date>;
  @ViewChild(EventsCalendarHeaderComponent) calendarHeader!: EventsCalendarHeaderComponent;

  @Select(CollectivityState.collectivity)
  collectivity$!: Observable<CollectivityInterface>;

  @Select(CollectivityState.id)
  collectivityId$!: Observable<string>;

  @Select(CitiesState.cities)
  allCities$!: Observable<City[]>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly eventWebService: EventWebservice,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.city$ = combineLatest([
      this.route.queryParamMap.pipe(
        map((params) => params.get('cityPlaceId')),
        distinctUntilChanged()
      ),
      this.allCities$,
    ]).pipe(
      untilDestroyed(this),
      map(([cityPlaceId, allCities]: [string | null, City[]]) =>
        cityPlaceId ? allCities.find((city) => city.cityPlaceId === cityPlaceId) : undefined
      ),
      distinctUntilChanged()
    );

    combineLatest([
      this.city$.pipe(startWith(undefined), distinctUntilChanged()),
      this.allCities$.pipe(distinctUntilChanged()),
    ])
      .pipe(
        untilDestroyed(this),
        switchMap(([city, allCities]: [City | undefined, City[]]) => {
          const mainCity: City | undefined = allCities.length > 0 ? allCities[0] : undefined;
          return combineLatest([
            this.eventWebService.getEvents('', undefined, undefined, {
              ...{
                hitsPerPage: 1000,
              },
              ...(city
                ? { aroundLatLng: `${city.geo.lat}, ${city.geo.lon}`, aroundRadius: 5000 }
                : { aroundLatLng: `${mainCity?.geo.lat}, ${mainCity?.geo.lon}`, aroundRadius: 100000 }),
              filters: this.buildFilters(),
            }),
          ]);
        }),
        map(([{ results }]) => results[1].hits.map((e) => algoliaEventToCommonListEvent(e, [])) ?? 0)
      )
      .subscribe((events: CommonListEvent[]) => {
        this.events$.next(events);
        this.dates$.next([]);
        events.forEach((event) => {
          this.getEventDates(event);
        });
      });
  }

  getEventDates(event: CommonListEvent): void {
    if (event.scheduleStart && event.scheduleEnd && this.dates$.value !== undefined) {
      this.dates$.value.push(new Date(event.scheduleStart));
      for (
        let date = new Date(event.scheduleStart);
        date <= new Date(event.scheduleEnd);
        date.setDate(date.getDate() + 1)
      ) {
        this.dates$.value.push(new Date(date));
      }
    }
  }

  buildFilters(): string {
    const startTimestamp = new Date().getTime() / 1000;
    return `NOT status:draft AND NOT status:archived AND startDate >= ${startTimestamp}`;
  }

  changeDate(currentDate: Date | null): void {
    if (currentDate) {
      this.selectedDate$.next(currentDate);
      this.findEventsByDate(currentDate);
    }
  }

  findEventsByDate(currentDate: Date | null): void {
    const startDay = new Date(currentDate ?? new Date());
    startDay.setHours(0, 0, 0, 0);
    const startTimestamp = startDay.getTime() / 1000;

    const endDay = new Date(currentDate ?? new Date());
    endDay.setHours(23, 59, 59, 999);
    const endTimestamp = endDay.getTime() / 1000;

    this.currentEvents$.next(
      this.events$.value.filter((event) => {
        if (event.scheduleStart && event.scheduleEnd) {
          return this.checkDateEvent(
            event.scheduleStart.getTime() / 1000,
            event.scheduleEnd.getTime() / 1000,
            startTimestamp,
            endTimestamp
          );
        }
        return false;
      })
    );
  }

  checkDateEvent(
    eventDateStart: number,
    eventDateEnd: number,
    currentDayStart: number,
    currentDayEnd: number
  ): boolean {
    return (
      (eventDateStart >= currentDayStart && eventDateStart <= currentDayEnd) ||
      (eventDateStart <= currentDayStart && currentDayEnd <= eventDateEnd) ||
      (eventDateEnd >= currentDayStart && eventDateEnd <= currentDayEnd)
    );
  }

  goToEvent(id: string, origin: string): void {
    this.router.navigate(['/', FeaturesRoutingEnum.Events, id, origin], {
      state: { queryParams: this.route.snapshot.queryParams },
    });
    this.closeCalendar.emit(true);
  }

  trackByEvent(_: number, event: CommonListEvent): string {
    return event.id;
  }

  ngOnDestroy(): void {
    this.events$.complete();
  }

  changeMonth(dir: -1 | 1): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, dir);
  }
}

@Pipe({ name: 'dateClass' })
export class DateClassPipe implements PipeTransform {
  transform(dates: Date[]): MatCalendarCellClassFunction<Date> {
    return (cellDate: Date) => {
      if (!dates) return '';
      return dates.reduce<string>((acc: string, item: Date) => {
        const eventStartDate = new Date(item.getFullYear(), item.getMonth(), item.getDate());
        eventStartDate.setUTCHours(0, 0, 0, 0);

        const cellDateDay = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
        cellDateDay.setUTCHours(0, 0, 0, 0);

        if (acc !== '') return acc;

        if (eventStartDate.getTime() === cellDateDay.getTime()) {
          return 'highlight-date';
        }
        return '';
      }, '');
    };
  }
}

@NgModule({
  imports: [
    MatDatepickerModule,
    EventCalendarCardModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatRippleModule,
  ],
  declarations: [EventsCalendarComponent, DateClassPipe, EventsCalendarHeaderComponent],
  exports: [EventsCalendarComponent],
})
export class EventsCalendarModule {}
