import { AbstractControl, ValidationErrors } from '@angular/forms';
import dayjs from 'dayjs';

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return !value || value.length === 0;
}
const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
const URL_REGEXP =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/;
const PHONE_REGEXP = /^(?:(?:\+|00)(33|590|594|262|596|269|687|689|508|681)|0|)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
const SIRET_SIZE = 14;

function isNumeric(num: any): boolean {
  return (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num as number);
}

export class WizValidators {
  static email(control: AbstractControl): ValidationErrors | null {
    return isEmptyInputValue(control.value) || EMAIL_REGEXP.test(control.value) ? null : { email: true };
  }

  static url(control: AbstractControl): ValidationErrors | null {
    return isEmptyInputValue(control.value) || URL_REGEXP.test(control.value) ? null : { url: true };
  }

  static cityRequired(control: AbstractControl): ValidationErrors | null {
    return !control.value || control.value.cityPlaceId ? null : { cityRequired: true };
  }

  static objectIDRequired(control: AbstractControl): ValidationErrors | null {
    return !control.value || control.value.objectID ? null : { algoliaObjectRequired: true };
  }

  static cityPoleEmploiRequired(control: AbstractControl): ValidationErrors | null {
    return !control.value || control.value.libelle ? null : { cityRequired: true };
  }

  static noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    const isWhitespace =
      !!control.value && typeof control.value === 'string' ? control.value.trim().length === 0 : false;
    return !isWhitespace ? null : { whitespace: true };
  }

  static phone(control: AbstractControl): ValidationErrors | null {
    return isEmptyInputValue(control.value) || PHONE_REGEXP.test(control.value) ? null : { phone: true };
  }

  static siret(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    const value = control.value.trim().replace(/\s/g, '');

    if (!isNumeric(value) || value.length !== SIRET_SIZE) {
      return { siret: true };
    }

    let bal = 0;
    let total = 0;

    for (let i = SIRET_SIZE - 1; i >= 0; i--) {
      const step = (value.charCodeAt(i) - 48) * (bal + 1);
      total += step > 9 ? step - 9 : step;
      bal = 1 - bal;
    }

    return total % 10 === 0 ? null : { siret: true };
  }

  static futureDate(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    const currentDate = new Date();
    const candidateDate = new Date(control.value);

    return dayjs(candidateDate).diff(currentDate, 'day') < 0 ? { futureDate: true } : null;
  }
}
