import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-events-calendar-header',
  templateUrl: './events-calendar-header.component.html',
  styleUrls: ['./events-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsCalendarHeaderComponent {
  @Input() currentDate!: Date;
  @Output() previousMonth = new EventEmitter();
  @Output() nextMonth = new EventEmitter();
}
