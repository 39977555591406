import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'include',
  pure: true,
})
export class IncludePipe implements PipeTransform {
  transform(haystack: string[], needles: string[]): boolean {
    return needles.some((needle) => haystack.includes(needle));
  }
}

@NgModule({
  declarations: [IncludePipe],
  imports: [CommonModule],
  providers: [IncludePipe],
  exports: [IncludePipe],
})
export class IncludePipeModule {}
