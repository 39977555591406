/* eslint-disable */
import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii/angular-utilities';
import { buildConfig } from './build-config';

const packageJsonVersionAtBuild = require('../../package.json').version; // eslint-disable-line

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const production: boolean = buildConfig === 'production';
const apiDomain = getRuntimeEnvVar('API_DOMAIN');
const apiPlatformStaging = getRuntimeEnvVar('API_PLATFORM');
const ciCommitSha = getBuildEnvVar('CI_COMMIT_SHA');
const deployAssetsUrl =
  production && ciCommitSha
    ? `https://storage.googleapis.com/wizbii-application-assets/b2b-collectivite/${ciCommitSha}/assets/`
    : null;

const strapiDomain = production ? 'strapi.wizbii.team' : 'strapi.team.staging.k8s.wizbii.us';

export const environment = {
  applicationId: 'app-collectivite',
  platform: getRuntimeEnvVar('PLATFORM'),
  apiDomain,
  isDeployed: ciCommitSha !== 'local',
  production,
  version: production ? packageJsonVersionAtBuild : getBuildEnvVar('GIT_BRANCH_NAME'),
  i18n: {
    lang: 'fr',
    locale: 'fr_FR',
  },
  bugsnag: {
    key: getRuntimeEnvVar('BUGSNAG_KEY'),
  },
  domain: {
    wizbii: getRuntimeEnvVar('WIZBII_ENV_FQDN'),
  },
  api: {
    account: `https://account.api.${apiDomain}`,
    authentication: `https://auth.${apiDomain}`,
    collectivityPrefix: `https://collectivite.api`,
    contact: `https://contact.api.${apiDomain}`,
    content: `https://content-api.${apiDomain}`,
    event: `https://event.api.${apiDomain}`,
    file: `https://file.${apiDomain}`,
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${apiDomain}`,
    job: `https://job-api.${apiDomain}`,
    opsCompany: `https://company-api.${apiDomain}`,
    opsEvent: `https://ops-event.api.${apiDomain}`,
    permii: `https://permii.api.${apiDomain}`,
    place: `https://place.${apiDomain}`,
    poleEmploi: `https://api.emploi-store.fr`,
    school: `https://school-api.${apiDomain}`,
    suggestion: `https://suggestion.api.${apiDomain}`,
    fibii: `https://fibii.api.${apiDomain}`,
    consents: `https://consent.${apiDomain}`,
    subscription: `https://subscription.api.${apiDomain}`,
    survey: `https://survey-api.${apiDomain}`,
    strapi: `https://collectivite.${strapiDomain}/api`,
  },
  pagination: {
    item_per_page_articles: 18,
    item_per_page: 40,
    max_hits: 1000,
  },
  appId: {
    wizbiiDrive: 'permii-app',
    wizbiiMoney: 'fibii',
  },
  algolia: {
    applicationId: getRuntimeEnvVar('ALGOLIA_APP_ID'),
    apiKey: getRuntimeEnvVar('ALGOLIA_API_KEY'),
    blogName: 'b2b-collectivite',
    indexName: {
      articles: 'content_article_b2b-collectivite',
      categories: 'content_facet_b2b-collectivite_category',
    },
    index: {
      driveAdvices: `${apiPlatformStaging}_content_article_wizbii-drive`,
      driveAdvicesCategories: `${apiPlatformStaging}_content_facet_wizbii-drive_category`,
      moneyAdvices: `${apiPlatformStaging}_content_article_wizbii-money`,
      moneyAdvicesCategories: `${apiPlatformStaging}_content_facet_wizbii-money_category`,
      events: `${apiPlatformStaging}_b2b_collectivite_event`,
      formations: (collectivityId: string) => `${apiPlatformStaging}_b2b_collectivite_course_${collectivityId}`,
      formations1j1s: `${apiPlatformStaging}_formation`,
      jobs: `${apiPlatformStaging}_jobs`,
      strapiArticles: `${apiPlatformStaging}_strapi_cms_collectivite_api::article.article`,
    },
  },
  blogId: {
    wizbiiDrive: 'wizbii-drive',
    wizbiiMoney: 'wizbii-money',
  },
  urls: {
    account: {
      base: `https://account.${apiDomain}`,
    },
    drive: {
      base: `https://app.drive.${apiDomain}`,
    },
    money: {
      base: `https://money.${apiDomain}`,
    },
  },
  deployAssetsUrl,
  wizbiiDriveContents: 'https://storage.googleapis.com/wizbii/b2b-collectivite/content-config/wizbii-drive',
  wizbiiFiles: getRuntimeEnvVar('FILE_API_BUCKET'),
  moneySuggestionSetId: getRuntimeEnvVar('SUGGESTION_SET_ID'),
  wizbiiImages: 'https://storage.googleapis.com/wizbii-images/B2Bcollectivite',
};

if (!production) {
  import('zone.js/dist/zone-error' as any); // Included with Angular CLI.
}
