import { Injectable } from '@angular/core';
import { ProfileInterface } from '@models/profile/profile';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Logout } from '@stores/jwt/jwt.actions';
import { JwtState } from '@stores/jwt/jwt.state';
import { ProfileDelete, ProfileGet, ProfilePut, ProfileRefresh } from '@stores/profile/profile.actions';
import { AccountWebService } from '@webservices/account/account.web-service';
import { ProfileWebservice } from '@webservices/profile/profile.webservice';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type ProfileStateModel = ProfileInterface | null;

@State<ProfileStateModel>({
  name: 'profile',
  defaults: null,
})
@Injectable()
export class ProfileState implements NgxsOnInit {
  constructor(
    private readonly store: Store,
    private readonly webService: ProfileWebservice,
    private readonly accountWebService: AccountWebService
  ) {}

  ngxsOnInit(_: StateContext<ProfileStateModel>): void {
    this.store
      .select(JwtState.userId)
      .pipe(filter((userId) => !!userId))
      .subscribe((userId) => userId && this.store.dispatch(new ProfileGet(userId)));
  }

  @Action(ProfileGet)
  doGet(context: StateContext<ProfileStateModel>, { id }: ProfileGet): Observable<ProfileStateModel> {
    return this.webService.get(id).pipe(map(context.setState));
  }

  @Action(ProfileRefresh)
  doRefresh(context: StateContext<ProfileStateModel>): Observable<ProfileStateModel> | null {
    const profileId = context.getState()?.id;

    return profileId ? this.store.dispatch(new ProfileGet(profileId)) : null;
  }

  @Action(ProfilePut)
  doUpdate(context: StateContext<ProfileInterface>, { profile }: ProfilePut): Observable<ProfileStateModel> | null {
    return this.webService
      .updateProfile(profile)
      .pipe(map((profileUpdated) => context.setState({ ...context.getState(), ...profileUpdated })));
  }

  @Action(ProfileDelete)
  doDelete(context: StateContext<ProfileStateModel>): Observable<ProfileStateModel> | null {
    const profileId = context.getState()?.id;

    return profileId
      ? this.accountWebService.deleteAccount(profileId).pipe(
          map(() => {
            this.store.dispatch(new Logout());
            return context.setState(null);
          })
        )
      : null;
  }
}
