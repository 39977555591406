import { JwtTokens } from '@wizbii/jwt';

export class Init {
  static readonly type = '[Session] Init';
  constructor(public tokens?: JwtTokens) {}
}

export class GetUserInfo {
  static readonly type = '[Session] GetUserInfo';
  constructor(public userId: string) {}
}

export class PatchUserWithService {
  static readonly type = '[Session] PatchUserWithService';
  constructor(public serviceId: string) {}
}
