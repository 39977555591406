<div [class]="mainContainerClass" cdkTrapFocus (appPressEscapeKey)="closePanel('Click close')">
  <section class="menu-panel">
    <div class="menu-scrollable">
      <header class="menu-scrollable__header">
        <button
          class="menu-scrollable__close-btn"
          type="button"
          (click)="closePanel('Click close')"
          aria-label="Fermer le menu"
          [tabindex]="isOpen ? 0 : -1"
        >
          <mat-icon svgIcon="close" role="presentation"></mat-icon>
        </button>

        <a class="menu-scrollable__logo-btn" [routerLink]="['/']" (click)="closePanel('Click Logo')">
          <img [src]="logoSrc$ | async" alt="Logo"
        /></a>
      </header>

      <nav class="nav-list">
        <ul class="ul-clean">
          <li
            *ngFor="let menuItem of menuItems; trackBy: trackByItem; let index = index"
            class="nav-list__item"
            [class.nav-list__item--hide]="
              (displaySubItem$ | async) !== undefined && (displaySubItem$ | async) !== menuItem
            "
          >
            <button
              *ngIf="menuItem.subItems?.length"
              class="btn-clean nav-list__item__button"
              [id]="menuItemId + '-' + index"
              [class.nav-list__item__button--selected]="(displaySubItem$ | async) === menuItem"
              [class.nav-list__item__button--active]="currentNestedNavigation === menuItem"
              (click)="displaySubItem(menuItem)"
              [tabindex]="isOpen ? 0 : -1"
            >
              <span class="nav-list__item__button__arrow"
                ><mat-icon svgIcon="arrow_left" role="presentation"></mat-icon></span
              >{{ menuItem.label }}
            </button>

            <ng-container *ngIf="!menuItem.subItems?.length">
              <a
                *ngIf="menuItem.redirect"
                class="nav-list__item__link"
                target="_blank"
                [id]="menuItemId + '-' + index"
                [tabindex]="isOpen ? 0 : -1"
                [href]="menuItem.url"
                (click)="closePanel(menuItem.eventAction)"
                >{{ menuItem.label }}
              </a>
              <a
                *ngIf="!menuItem.redirect"
                class="nav-list__item__link"
                [id]="menuItemId + '-' + index"
                [tabindex]="isOpen ? 0 : -1"
                [routerLink]="menuItem.url"
                routerLinkActive="nav-list__item__link--active"
                [routerLinkActiveOptions]="{ exact: !!menuItem.matchExactUrl }"
                (click)="closePanel(menuItem.eventAction)"
                >{{ menuItem.label }}
              </a>
            </ng-container>
          </li>

          <li class="sign">
            <span class="nav-list__divider"></span>

            <app-events-agenda [isInHeader]="false" (closeMenu)="closePanel($event)"></app-events-agenda>

            <ng-container *ngIf="(profile$ | async) === null">
              <ul class="sign__ctas">
                <li>
                  <a class="btn sign__ctas__up" aria-label="S'inscire" [routerLink]="['/', FeaturesRoutingEnum.Signup]"
                    >S'inscrire</a
                  >
                </li>
                <li>
                  <a
                    class="btn-clean sign__ctas__in"
                    aria-label="Se connecter"
                    [routerLink]="['/', FeaturesRoutingEnum.Signin]"
                    >Se connecter</a
                  >
                </li>
              </ul>
            </ng-container>

            <ng-container *ngIf="profile$ | async as profile">
              <app-user-dropdown
                [userName]="profile.firstName + ' ' + profile.lastName"
                [roles]="roles$ | async"
                (closeMenu)="closePanel($event)"
                [extranetUrl]="extranetUrl$ | async"
              ></app-user-dropdown>
            </ng-container>
          </li>
        </ul>
      </nav>

      <ul class="ul-clean socials">
        <li class="socials__item" *ngFor="let socialNetwork of socialNetworks$ | async; trackBy: trackBySocial">
          <a
            class="btn-clean btn-round socials__item__button"
            [attr.aria-label]="socialNetwork.label"
            target="_blank"
            [href]="socialNetwork.url"
            [tabindex]="
              (isOpen && (displaySubItem$ | async) === undefined) ||
              (isOpen && (displaySubItem$ | async) !== undefined && (isWebNotIpadPro$ | async))
                ? 0
                : -1
            "
            (click)="trackEvent(eventCategory, 'Click ' + socialNetwork.label)"
          >
            <mat-icon
              class="socials__item__button__icon"
              [svgIcon]="socialNetwork.label"
              role="presentation"
            ></mat-icon>
          </a>
        </li>
      </ul>

      <div class="ellipse ellipse__first">
        <mat-icon svgIcon="ellipse1" role="presentation" [inline]="true"></mat-icon>
      </div>
      <div class="ellipse ellipse__second">
        <mat-icon svgIcon="ellipse2" role="presentation" [inline]="true"></mat-icon>
      </div>
      <div class="ellipse ellipse__third">
        <mat-icon svgIcon="ellipse3" role="presentation" [inline]="true"></mat-icon>
      </div>
    </div>
  </section>

  <app-sub-menu-panel
    *ngIf="displaySubItem$ | async as displaySubItem"
    [isWebNotIpadPro]="(isWebNotIpadPro$ | async) === true"
    [displaySubItem]="displaySubItem"
    [isOpen]="isOpen"
    (navigate)="closePanel($event)"
  ></app-sub-menu-panel>
</div>
<img [src]="menuHero1Src$ | async" alt="" class="hero1" loading="lazy" />
<img [src]="menuHero2Src$ | async" alt="" class="hero2" loading="lazy" />
<img [src]="menuHero3Src$ | async" alt="" class="hero3" loading="lazy" />
