import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ProfileInterface } from '@models/profile/profile';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfileWebservice {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = `${environment.api.collectivityPrefix}.${environment.apiDomain}`;
  }

  get(id: string): Observable<ProfileInterface> {
    return this.http.get<ProfileInterface>(`${this.baseUrl}/v1/profile/${id}`);
  }

  updateProfile(profile: Partial<ProfileInterface>): Observable<Partial<ProfileInterface>> {
    if (!profile.id) {
      return of(profile);
    }
    return this.http.put<Partial<ProfileInterface>>(`${this.baseUrl}/v1/profile/${profile.id}`, profile);
  }
}
