import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactPageInterface } from '@b2b/models';
import { WizValidators } from '@commons/validators';
import { WindowResizeService } from '@core/services/window-resize/window-resize.service';
import { environment } from '@environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { ContactResource } from '@wizbii/models';
import { ContactWebservice } from '@wizbii/webservices';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

interface ContactUsData {
  isMobile: boolean;
  templateKey?: string;
}

@UntilDestroy()
@Component({
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsComponent implements OnInit {
  @HostBinding('class.dialog-form') dialogForm = true;
  @HostBinding('class.dialog-form--saving') saving = false;
  @HostBinding('class.dialog-form--saved') saved = false;

  @Select(CollectivityState.contactPage)
  collectivityContactPage$!: Observable<ContactPageInterface | undefined>;

  form: FormGroup;
  isMobile: boolean;
  templateKey?: string;

  get ratio$(): Observable<number> {
    return this.windowResizeService.ratio$;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly contactWebService: ContactWebservice,
    readonly dialogRef: MatDialogRef<ContactUsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactUsData,
    private readonly windowResizeService: WindowResizeService
  ) {
    this.isMobile = data.isMobile;
    this.templateKey = data.templateKey;

    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, WizValidators.email]),
      phone: new FormControl('', []),
      subject: new FormControl('', [Validators.required]),
      content: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    if (this.isMobile) {
      this.dialogRef
        .backdropClick()
        .pipe(untilDestroyed(this))
        .subscribe({ next: () => this.closeDialog() });

      this.ratio$.pipe(untilDestroyed(this)).subscribe({
        next: (ratio) => {
          this.dialogRef.updateSize('100vw', `${100 * ratio - 50}px`);
          this.dialogRef.addPanelClass('wizbii-mobile-panel-open');
        },
      });
    }
  }

  closeDialog(): void {
    if (this.isMobile) {
      this.dialogRef.addPanelClass('wizbii-mobile-panel-close');
      window.setTimeout(() => {
        this.dialogRef.close();
      }, 100);
    } else {
      this.dialogRef.close();
    }
  }

  doSubmit(contactResource: ContactResource): void {
    this.saving = true;

    this.collectivityContactPage$
      .pipe(
        take(1),
        switchMap((contactPage) => {
          return this.contactWebService.send({
            recipientEmail: environment.platform === 'prod' ? contactPage?.contactEmail : 'moulteampass@wizbii.com',
            ...(this.templateKey ? { attributes: { templateKey: this.templateKey } } : {}),
            ...contactResource,
            appId: environment.applicationId,
          });
        })
      )
      .subscribe({
        next: () => {
          this.saving = false;
          this.saved = true;
          this.cdr.markForCheck();
        },
        error: () => {
          this.saving = false;
          this.cdr.markForCheck();
        },
      });
  }
}
