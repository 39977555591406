<app-toaster></app-toaster>

<app-header
  *ngIf="(hideHeader$ | async) === false"
  class="header"
  [class.header--fullscreen]="(displayFullscreen$ | async) === true"
  [class.header--white]="(isWhiteHeader$ | async) === true"
  [displayFullscreen]="(displayFullscreen$ | async) === true"
  [hideEllipses]="(hideEllipses$ | async) === true"
  [isHeaderStuck]="(isStuck$ | async) === true"
  [noStickyHeader]="(noStickyHeader$ | async) === true"
  [isWhiteHeader]="(isWhiteHeader$ | async) === true"
  [withoutShadowHeader]="(withoutShadowHeader$ | async) === true"
  [isFullWidth]="(isFullWidth$ | async) === true"
></app-header>

<main
  [id]="
    (displayFullscreen$ | async) === true
      ? (stickyHeader$ | async) === true
        ? 'smallHeader'
        : undefined
      : (stickyHeader$ | async) === true
      ? 'smallHeader'
      : 'mainContent'
  "
>
  <app-scroll-top *ngIf="(hideHeader$ | async) === false && (hideFooter$ | async) === false"></app-scroll-top>
  <router-outlet></router-outlet>
</main>

<app-footer
  *ngIf="((displayFullscreen$ | async) === false && (hideFooter$ | async) === false) || (showFooter$ | async) === true"
  (contactUs)="openContactForm()"
></app-footer>
