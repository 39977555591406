import { MultipleQueriesResponse, SearchOptions } from '@algolia/client-search';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { AlgoliaEventResponse, Event, toEvent } from '@models/event/event';
import { COLLECTIVITY_WEBSERVICES_TOKEN, CollectivityWebservicesTokenInterface } from '@webservices/injection-tokens';
import { AlgoliaClientService } from '@wizbii/algolia';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EventWebservice {
  private readonly url: string;

  constructor(
    @Inject(COLLECTIVITY_WEBSERVICES_TOKEN)
    collectivityWebservicesToken: CollectivityWebservicesTokenInterface,
    private readonly http: HttpClient,
    private readonly algoliaClientService: AlgoliaClientService
  ) {
    this.url = `${environment.api.collectivityPrefix}.${collectivityWebservicesToken.collectivityDomain}`;
  }

  getEvent(eventId: string | null): Observable<Event> {
    return this.http.get<Event>(`${this.url}/v1/event/${eventId}`).pipe(map(toEvent));
  }

  getEvents(
    query: string,
    type?: string,
    online?: boolean,
    searchParams?: SearchOptions
  ): Observable<MultipleQueriesResponse<AlgoliaEventResponse>> {
    return from(
      this.algoliaClientService.client.search<AlgoliaEventResponse>([
        // query for type facets
        {
          indexName: environment.algolia.index.events,
          query,
          params: {
            hitsPerPage: 0,
            facets: ['type', 'online'],
            ...(searchParams?.aroundLatLng ? { aroundLatLng: searchParams.aroundLatLng } : {}),
            ...(searchParams?.aroundRadius ? { aroundRadius: searchParams.aroundRadius } : {}),
            facetFilters: [...(type ? [`type:${type}`] : []), ...(online ? ['online:true'] : [])],
            filters: searchParams?.filters,
          },
        },

        // query for hits itself
        {
          indexName: environment.algolia.index.events,
          query,
          params: {
            ...searchParams,
            ...(searchParams?.aroundLatLng ? { aroundLatLng: searchParams.aroundLatLng } : {}),
            ...(searchParams?.aroundRadius ? { aroundRadius: searchParams.aroundRadius } : {}),
            facetFilters: [...(type ? [`type:${type}`] : []), ...(online ? ['online:true'] : [])],
          },
        },
      ])
    );
  }

  getFacetValue(facetName: string, facetQuery: string): Observable<string[]> {
    return from(
      this.algoliaClientService.client.searchForFacetValues([
        {
          indexName: environment.algolia.index.events,
          params: {
            facetName,
            facetQuery,
          },
        },
      ])
    ).pipe(
      map((results) => results[0]),
      map((result) => result.facetHits.map((facet) => facet.value))
    );
  }
}
