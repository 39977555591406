import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomMetaService } from '@core/services/meta-service/meta.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit, OnDestroy {
  constructor(
    private readonly route: ActivatedRoute,
    @Optional() @Inject(RESPONSE) private readonly response: Response,
    private readonly metaService: CustomMetaService
  ) {}

  ngOnInit(): void {
    this.metaService.updateForSeo({
      title: `Erreur 404 ! - Page introuvable`,
    });

    if (this.response) {
      this.response.statusCode = 404;
      this.response.statusMessage = 'Not Found';
    } else if (this.route.snapshot.data.unhandled404) {
      throw new Error('Unhandled 404');
    }
  }

  back(): void {
    window.history.back();
  }

  ngOnDestroy(): void {
    this.metaService.restoreForSeo();
  }
}
