import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { LegalPageType } from '@b2b/models';
import { AvailableServiceGuard } from '@core/guards/available-service.guard';
import { LoggedGuard } from '@core/guards/logged.guard';
import { NotLoggedGuard } from '@core/guards/not-logged.guard';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotFoundComponent } from '@features/not-found/not-found.component';
import { SharedModule } from '@shared/shared.module';
import { IMAGINARY_PIPE_CONFIG } from '@wizbii/angular-utilities';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CityWebservice,
  CONTACT_API_CONFIG,
  ContactWebservice,
  PLACE_API_CONFIG,
} from '@wizbii/webservices';

/* eslint-disable sonarjs/no-duplicate-string */
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then((module) => module.HomeModule),
  },
  {
    path: FeaturesRoutingEnum.Jobs,
    canActivate: [AvailableServiceGuard],
    loadChildren: () => import('./jobs/jobs.module').then((module) => module.JobsModule),
  },
  {
    path: FeaturesRoutingEnum.Article,
    canActivate: [AvailableServiceGuard],
    loadChildren: () => import('./articles/articles.module').then((module) => module.ArticlesModule),
  },
  {
    path: FeaturesRoutingEnum.Formation,
    canActivate: [AvailableServiceGuard],
    loadChildren: () => import('./formations/formations.module').then((module) => module.FormationsModule),
  },
  {
    path: FeaturesRoutingEnum.Events,
    canActivate: [AvailableServiceGuard],
    loadChildren: () => import('./events/events.module').then((module) => module.EventsModule),
  },
  {
    path: FeaturesRoutingEnum.Money,
    canActivate: [AvailableServiceGuard],
    data: { isWhiteHeader: true, stickyHeader: true, showFooter: true, disableTopScrolling: false },
    loadChildren: () => import('./money/money.module').then((module) => module.MoneyModule),
  },
  {
    path: FeaturesRoutingEnum.Drive,
    canActivate: [AvailableServiceGuard],
    loadChildren: () => import('./drive/drive.module').then((module) => module.DriveModule),
    data: { isWhiteHeader: true, stickyHeader: true, showFooter: true, disableTopScrolling: true },
  },
  {
    path: FeaturesRoutingEnum.Accessibility,
    data: { type: LegalPageType.ACCESSIBILITY },
    loadChildren: () => import('./legal/legal.module').then((module) => module.LegalModule),
  },
  {
    path: FeaturesRoutingEnum.CGU,
    data: { type: LegalPageType.CGU },
    loadChildren: () => import('./legal/legal.module').then((module) => module.LegalModule),
  },
  {
    path: FeaturesRoutingEnum.Privacy,
    data: { type: LegalPageType.CONFIDENTIALITE },
    loadChildren: () => import('./legal/legal.module').then((module) => module.LegalModule),
  },
  {
    path: FeaturesRoutingEnum.LegalMentions,
    data: { type: LegalPageType.MENTIONS },
    loadChildren: () => import('./legal/legal.module').then((module) => module.LegalModule),
  },
  {
    path: FeaturesRoutingEnum.Contact,
    loadChildren: () => import('./contact/contact.module').then((module) => module.ContactModule),
  },
  {
    path: FeaturesRoutingEnum.Unsubscribe,
    loadChildren: () => import('./unsubscribe/unsubscribe.module').then((module) => module.UnsubscribeModule),
    data: { displayFullscreen: true, hideHeader: true, hideFooter: true },
  },
  {
    path: FeaturesRoutingEnum.NotFound,
    component: NotFoundComponent,
    data: { displayFullscreen: true, isWhiteHeader: true },
  },
  {
    path: FeaturesRoutingEnum.Signup,
    canActivate: [NotLoggedGuard],
    loadChildren: () => import('./sign-up/sign-up.module').then((module) => module.SignUpModule),
    data: { displayFullscreen: true, hideHeader: true, hideFooter: true },
  },
  {
    path: FeaturesRoutingEnum.Signin,
    canActivate: [NotLoggedGuard],
    loadChildren: () => import('./sign-in/sign-in.module').then((module) => module.SignInModule),
    data: { displayFullscreen: true, hideHeader: true, hideFooter: true },
  },
  {
    path: FeaturesRoutingEnum.User,
    canActivate: [LoggedGuard],
    loadChildren: () => import('./user/user.module').then((module) => module.UserModule),
    data: { displayFullscreen: true, isWhiteHeader: true },
  },

  {
    path: '**',
    component: NotFoundComponent,
    data: { unhandled404: true, displayFullscreen: true, isWhiteHeader: true },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), QuicklinkSharedModule, SharedModule, MatIconModule],
  declarations: [NotFoundComponent],
  providers: [
    NotLoggedGuard,
    LoggedGuard,
    AvailableServiceGuard,
    ContactWebservice,
    {
      provide: CONTACT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.contact,
      },
    },
    CityWebservice,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
    {
      provide: IMAGINARY_PIPE_CONFIG,
      useValue: {
        imaginaryUrl: environment.api.imaginary,
      },
    },
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    AuthenticationWebservice,
  ],
})
export class FeaturesModule {}
