import { isPlatformBrowser, isPlatformServer, ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Event, NavigationEnd, Router, Scroll } from '@angular/router';
import { fromIntersectionObserver } from '@commons/intersection-observer';
import { ContactUsComponent } from '@core/components/contact-us/contact-us.component';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { WindowResizeService } from '@core/services/window-resize/window-resize.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { LoginComponent } from '@features/sign-in/login/login.component';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { SetTokens } from '@stores/jwt/jwt.actions';
import { JwtState } from '@stores/jwt/jwt.state';
import { AuthenticationWebService } from '@webservices/authentication/authentication.web-service';
import { BehaviorSubject, combineLatest, first, fromEvent, Observable, of, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, take } from 'rxjs/operators';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit, AfterViewInit {
  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.accessibility-on') accessibilityEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  @HostBinding('class.accessibility-off')
  get accessibilityDisabled(): boolean {
    return !this.accessibilityEnabled;
  }

  get isMobile$(): Observable<boolean> {
    return this.breakpointsService.isMobile$;
  }

  @Select(CollectivityState.id)
  collectivityId$!: Observable<string>;

  displayFullscreen$!: Observable<boolean>;
  hideEllipses$!: Observable<boolean>;
  hideFooter$!: Observable<boolean>;
  showFooter$!: Observable<boolean>;
  hideHeader$!: Observable<boolean>;
  isWhiteHeader$!: Observable<boolean>;
  noStickyHeader$!: Observable<boolean>;
  stickyHeader$!: Observable<boolean>;
  withoutShadowHeader$!: Observable<boolean>;
  isFullWidth$!: Observable<boolean>;

  isStuck$ = new BehaviorSubject<boolean>(false);

  whiteListObserveHeaders = [FeaturesRoutingEnum.Drive];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    viewportScroller: ViewportScroller,
    private readonly store: Store,
    private readonly breakpointsService: BreakpointsService,
    private readonly dialog: MatDialog,
    private readonly windowResizeService: WindowResizeService,
    private readonly authWebService: AuthenticationWebService,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
  ) {
    this.scrollPositionRestoration(router, viewportScroller);
    this.triggerObserveHeaders(router);
  }

  private scrollPositionRestoration(router: Router, viewportScroller: ViewportScroller): void {
    router.events
      .pipe(
        filter((e: Event) => e instanceof Scroll),
        map((e) => [e as Scroll, window.location.pathname]),
        take(1)
      )
      .subscribe({
        next: () => {
          viewportScroller.scrollToPosition([0, 0]);
        },
      });

    const isTopScrollingDisabled$ = this.store.select(RouterState.state).pipe(
      map((state: any) => state?.data?.disableTopScrolling),
      distinctUntilChanged()
    );

    router.events
      .pipe(
        filter((e: Event) => e instanceof Scroll),
        switchMap((e) => combineLatest([of(e), of(window.location.pathname), isTopScrollingDisabled$])),
        filter(([_, __, isTopScrollingDisabled]) => !isTopScrollingDisabled),
        pairwise()
      )
      .subscribe({
        next: ([[_, previousUrl], [currentEvent, currentUrl, isTopScrollingDisabled]]: any) => {
          if (currentEvent.position && isTopScrollingDisabled !== false) {
            viewportScroller.scrollToPosition(currentEvent.position);
          } else if (currentEvent.anchor && isTopScrollingDisabled !== false) {
            viewportScroller.scrollToAnchor(currentEvent.anchor);
          } else if (previousUrl !== currentUrl) {
            viewportScroller.scrollToPosition([0, 0]);
          }
        },
      });
  }

  private triggerObserveHeaders(router: Router): void {
    router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        map((e) => (e as NavigationEnd).url),
        filter((url) => !!this.whiteListObserveHeaders.find((e) => e === url.split('/')[1])),
        distinctUntilChanged()
      )
      .subscribe({
        next: () => {
          this.observeHeaders(document.body);
        },
      });
  }

  ngOnInit(): void {
    if (document.documentElement.lang === '') {
      document.documentElement.lang = environment.i18n.lang;
    }

    this.displayFullscreen$ = this.store
      .select(RouterState.state)
      .pipe(map((state: any) => !!state?.data?.displayFullscreen));

    this.hideEllipses$ = this.store.select(RouterState.state).pipe(map((state: any) => !!state?.data?.hideEllipses));

    this.hideFooter$ = this.store.select(RouterState.state).pipe(map((state: any) => !!state?.data?.hideFooter));

    this.hideHeader$ = this.store.select(RouterState.state).pipe(map((state: any) => !!state?.data?.hideHeader));

    this.showFooter$ = this.store.select(RouterState.state).pipe(map((state: any) => !!state?.data?.showFooter));

    this.isWhiteHeader$ = this.store.select(RouterState.state).pipe(map((state: any) => !!state?.data?.isWhiteHeader));

    this.isFullWidth$ = this.store.select(RouterState.state).pipe(map((state: any) => !!state?.data?.isFullWidth));

    this.withoutShadowHeader$ = this.store
      .select(RouterState.state)
      .pipe(map((state: any) => !!state?.data?.withoutShadowHeader));

    this.noStickyHeader$ = this.store
      .select(RouterState.state)
      .pipe(map((state: any) => !!state?.data?.noStickyHeader));

    this.stickyHeader$ = this.store.select(RouterState.state).pipe(map((state: any) => !!state?.data?.stickyHeader));

    this.windowResizeService.setWindowSize();

    fromEvent(window, 'resize', { passive: true }).subscribe({
      next: () => {
        this.windowResizeService.setWindowSize();
      },
    });

    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    fromEvent(window, 'touchstart', { passive: true }).subscribe({
      next: () => {
        this.hoverEnabled = false;
      },
    });

    combineLatest([
      this.route.queryParamMap,
      this.store.select(JwtState).pipe(
        filter((jwtState) => !!jwtState),
        first()
      ),
    ])
      .pipe(
        switchMap(([queryParamMap, jwtState]) => {
          if (queryParamMap.get(LoginComponent.TOKEN_KEY) && !jwtState.tokens && isPlatformBrowser(this.platformId)) {
            return this.collectivityId$.pipe(
              switchMap((collectivityId) =>
                this.authWebService.fromLoginToken(queryParamMap.get(LoginComponent.TOKEN_KEY) ?? '', collectivityId)
              )
            );
          }
          return of();
        }),
        filter((tokens) => !!tokens),
        first(),
        switchMap((tokens) => this.store.dispatch(new SetTokens(tokens, true)))
      )
      .subscribe({
        next: () => this.router.navigate([], { relativeTo: this.route, queryParams: {}, queryParamsHandling: '' }),
        error: () => this.router.navigate(['/', FeaturesRoutingEnum.Signin]),
      });
  }

  ngAfterViewInit(): void {
    this.observeHeaders(document.body);

    document.addEventListener('sticky-change', (e) => {
      const [header, stuck] = [(e as CustomEvent).detail.target, (e as CustomEvent).detail.stuck];
      header.setAttribute('stuck', stuck);
      this.isStuck$.next(stuck);
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  observeHeaders(container: Element): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    // Add the top sentinels to each section and attach an observer.
    const headerSentinel = this.addSentinel(container);

    if (!headerSentinel) {
      return;
    }

    combineLatest([fromIntersectionObserver(headerSentinel as HTMLElement), this.noStickyHeader$, this.stickyHeader$])
      .pipe(debounceTime(10))
      .subscribe(([record, noStickyHeader, stickyHeader]) => {
        const targetInfo = record.boundingClientRect;
        const stickyTarget = record.target.parentElement?.querySelector('.header');
        const rootBoundsInfo = record.rootBounds;

        // Stay sticking if
        if (!!stickyTarget && !!stickyHeader) {
          this.fireEvent(true, stickyTarget);
        } else {
          // Started sticking.
          if (
            !!stickyTarget &&
            rootBoundsInfo?.top !== undefined &&
            targetInfo.bottom < rootBoundsInfo?.top &&
            !noStickyHeader
          ) {
            this.fireEvent(true, stickyTarget);
          }

          // Stopped sticking.
          if (
            (!!stickyTarget &&
              rootBoundsInfo?.top !== undefined &&
              targetInfo.bottom >= rootBoundsInfo?.top &&
              targetInfo.bottom < rootBoundsInfo?.bottom &&
              !noStickyHeader) ||
            // for pages like drive and money where header stay fixed
            // fire sticky event to false in order to automatically stick header
            (!!noStickyHeader && !!stickyTarget)
          ) {
            this.fireEvent(false, stickyTarget);
          }
        }
      });
  }

  addSentinel(container: Element): HTMLDivElement | undefined {
    const sentinel = document.createElement('div');
    sentinel.classList.add('sticky-header-sentinel');
    return container.querySelector('.header')?.parentElement?.appendChild(sentinel);
  }

  fireEvent(stuck: boolean, target: Element): void {
    const e = new CustomEvent('sticky-change', { detail: { stuck, target } });
    document.dispatchEvent(e);
  }

  openContactForm(): void {
    this.isMobile$.pipe(take(1)).subscribe({
      next: (isMobile) => {
        this.dialog.open(ContactUsComponent, {
          panelClass: isMobile ? 'wizbii-mobile-panel--white' : 'wizbii-panel--white',
          backdropClass: 'wzb-backdrop',
          minWidth: isMobile ? '100vw' : '50vw',
          autoFocus: false,
          data: {
            isMobile,
            templateKey: 'email_contact',
          },
          disableClose: isMobile,
          position: isMobile ? { bottom: '0' } : {},
        });
      },
    });
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): void {
    this.hoverEnabled = event.pointerType === 'mouse';
  }

  @HostListener('document:keydown.tab', ['$event'])
  onKeydownTabHandler(_: KeyboardEvent): void {
    this.accessibilityEnabled = true;
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDownHandler(_: MouseEvent): void {
    this.accessibilityEnabled = false;
  }
}
