import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-checkmark-loader',
  templateUrl: './checkmark-loader.component.html',
  styleUrls: ['./checkmark-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckmarkLoaderComponent {
  @Input()
  @HostBinding('attr.data-loading')
  loading = false;
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [CheckmarkLoaderComponent],
  exports: [CheckmarkLoaderComponent],
})
export class CheckmarkLoaderModule {}
