<ol
  class="sub-menu-panel"
  [@slide]="
    isWebNotIpadPro
      ? { value: '', params: { duration: 200, transform: 'translateX(-100%)', width: '0px' } }
      : { value: '', params: { duration: 200, transform: 'translateY(100%)', width: '100%' } }
  "
>
  <li
    *ngFor="let subItem of displaySubItem?.subItems; trackBy: trackBySubItem; let index = index"
    class="sub-menu-panel__item"
  >
    <a
      *ngIf="subItem.redirect"
      [id]="'sub-menu-panel-' + index"
      [tabindex]="isOpen ? 0 : -1"
      class="sub-menu-panel__item__link"
      [href]="subItem.url"
      target="_blank"
      (click)="navigate.emit(subItem.eventAction)"
      >{{ subItem.label }}</a
    >
    <a
      *ngIf="!subItem.redirect"
      [id]="'sub-menu-panel-' + index"
      [tabindex]="isOpen ? 0 : -1"
      class="sub-menu-panel__item__link"
      [routerLink]="subItem.url"
      (click)="navigate.emit(subItem.eventAction)"
      >{{ subItem.label }}</a
    >
  </li>
</ol>
