import { LegalPageType } from '@b2b/models';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';

export interface AvailableLegal {
  label: string;
  link: FeaturesRoutingEnum;
}

export const availableLegalsMap: Map<LegalPageType, AvailableLegal> = new Map([
  [LegalPageType.CGU, { label: 'CGU', link: FeaturesRoutingEnum.CGU }],
  [LegalPageType.MENTIONS, { label: 'Mentions légales', link: FeaturesRoutingEnum.LegalMentions }],
  [LegalPageType.ACCESSIBILITY, { label: 'Accessibilité', link: FeaturesRoutingEnum.Accessibility }],
  [LegalPageType.CONFIDENTIALITE, { label: 'Confidentialité', link: FeaturesRoutingEnum.Privacy }],
]);
