import { AlgoliaEventResponse, Event } from '@models/event/event';
import { EventForm, EventFormExternal } from '@models/event/event-form';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EventType } from '@models/ops-event/event-type';
import { OpsEvent, OpsEventJob, OpsEventLight } from '@models/ops-event/ops-event';
import { OrganizationLight } from '@models/organization/organization';
import { convertDate } from '@models/utils';
import { City } from '@wizbii/models';

export enum CommonEventStatusEnum {
  Draft = 'draft',
  Published = 'published',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Archived = 'archived',
}

export const DisplayCommonStatus: Record<CommonEventStatusEnum, string> = {
  [CommonEventStatusEnum.Draft]: 'Brouillon',
  [CommonEventStatusEnum.Published]: 'En ligne',
  [CommonEventStatusEnum.Completed]: 'Hors ligne',
  [CommonEventStatusEnum.Cancelled]: 'Annulé',
  [CommonEventStatusEnum.Archived]: 'Fini',
};

export enum CommonEventOriginEnum {
  Internal = 'interne',
  External = 'externe',
}
export interface CommonListEvent {
  id: string;
  name?: string;
  location?: City;
  areas?: City[];
  origin: CommonEventOriginEnum;
  type: string;
  thumbnail?: string;
  status: CommonEventStatusEnum;
  date?: Date;
  scheduleStart?: Date;
  scheduleEnd?: Date;
  distance?: number;
  organizationName?: string;
  organizationId?: string;
  online: boolean;
  salonUrl?: string;
  geoDistance?: number;
}

export interface CommonEvent {
  id: string;
  name: string;
  status: CommonEventStatusEnum;
  type: string;
  location?: City;
  areas?: City[];
  date?: Date;
  scheduleStart?: Date;
  scheduleEnd?: Date;
  isFormEnabled?: boolean;
  redirectUrl?: string;
  form?: EventForm;
  isFormExternalEnabled?: boolean;
  formExternal?: EventFormExternal;
  description?: string;
  metaDescription?: string;
  metaTitle?: string;
  jobsTitles?: string[];
  jobs?: OpsEventJob[];
  thumbnail?: string;
  smallSocialNetworkFileId?: string;
  bigSocialNetworkFileId?: string;
  origin: CommonEventOriginEnum;
  seo?: boolean;
  source?: string;
  organization: OrganizationLight;
  online: boolean;
  salonUrl?: string;
  geoDistance?: number;
  registrationProcedure?: string;
  receptionEmail?: string;
}

export const defaultImagesPhysicalEvent = {
  jpg: [
    'organize-people.jpg',
    'organize-people-2.jpg',
    'organize-people-3.jpg',
    'organize-people-4.jpg',
    'organize-people-5.jpg',
    'organize-people-6.jpg',
    'organize-people-7.jpg',
    'organize-people-8.jpg',
    'organize-people-9.jpg',
    'organize-people-10.jpg',
    'organize-people-11.jpg',
    'organize-people-12.jpg',
    'organize-people-13.jpg',
    'organize-people-14.jpg',
    'organize-people-15.jpg',
    'organize-people-16.jpg',
    'organize-people-17.jpg',
  ],
  webp: [
    'organize-people.webp',
    'organize-people-2.webp',
    'organize-people-3.webp',
    'organize-people-4.webp',
    'organize-people-5.webp',
    'organize-people-6.webp',
    'organize-people-7.webp',
    'organize-people-8.webp',
    'organize-people-9.webp',
    'organize-people-10.webp',
    'organize-people-11.webp',
    'organize-people-12.webp',
    'organize-people-13.webp',
    'organize-people-14.webp',
    'organize-people-15.webp',
    'organize-people-16.webp',
    'organize-people-17.webp',
  ],
};

export const defaultImagesVirtualEvent = {
  jpg: ['call-people.jpg', 'call-people-2.jpg', 'call-people-3.jpg'],
  webp: ['call-people.webp', 'call-people-2.webp', 'call-people-3.webp'],
};

export function eventToCommonEvent(event: Event): CommonEvent {
  return {
    id: event.id,
    name: event.name,
    status: event.status as any,
    type: event.type,
    location: event.location ? new City(event.location) : undefined,
    areas: event.areas && event.areas.length ? event.areas.map((area) => new City(area)) : undefined,
    date: convertDate(event.startDate),
    scheduleStart: convertDate(event.startDate),
    scheduleEnd: convertDate(event.endDate),
    isFormEnabled: event.isFormEnabled,
    form: event.form,
    isFormExternalEnabled: event.isFormExternalEnabled,
    formExternal: event.formExternal,
    redirectUrl: event.redirectUrl,
    description: event.description,
    metaDescription: event.metaDescription,
    metaTitle: event.metaTitle,
    thumbnail: event.thumbnail,
    origin: CommonEventOriginEnum.External,
    seo: event.seo,
    source: event.source,
    online: event.online,
    salonUrl: event.salonUrl,
    organization: {
      id: event.organization?.id ?? event.organizationId,
      name: event.organization?.name ?? event.organizationName,
      domain: event.organization?.domain,
      logo: event.organization?.logo,
    },
    registrationProcedure: event.registrationProcedure,
    receptionEmail: event.receptionEmail,
  };
}

export function algoliaEventToCommonEvent(event: AlgoliaEventResponse, eventTypes: EventType[]): CommonEvent {
  const eventType = eventTypes.find((e) => e.id === event.type);
  return {
    id: event.id ?? event.objectID,
    name: event?.name ? event.name : event?.location ? `${eventType!.name} à ${event.location.city}` : eventType!.name,
    status: event.status as any,
    type: event.type,
    location: event.location ? new City(event.location) : undefined,
    areas: event.areas && event.areas.length ? event.areas.map((area) => new City(area)) : undefined,
    date: convertDate(event.startDate),
    scheduleStart: convertDate(event.startDate),
    scheduleEnd: convertDate(event.endDate),
    isFormEnabled: undefined,
    form: undefined,
    redirectUrl: event.redirectUrl,
    description: event.description,
    metaDescription: undefined,
    metaTitle: undefined,
    thumbnail: event.thumbnail,
    origin: event.source === 'wizbii' ? CommonEventOriginEnum.Internal : CommonEventOriginEnum.External,
    seo: undefined,
    organization: {
      name: event.organizationName,
      id: event.organizationId,
    },
    online: event.online,
    salonUrl: event.salonUrl,
    geoDistance: event._rankingInfo?.geoDistance,
  };
}

export function algoliaEventToCommonListEvent(event: AlgoliaEventResponse, eventTypes: EventType[]): CommonListEvent {
  const eventType = eventTypes.find((e) => e.id === event.type);
  return {
    id: event.id ?? event.objectID,
    name: event?.name ? event.name : event?.location ? `${eventType!.name} à ${event.location.city}` : eventType!.name,
    location: event.location ? new City(event.location) : undefined,
    areas: event.areas && event.areas.length ? event.areas.map((area) => new City(area)) : undefined,
    origin: event.source === 'wizbii' ? CommonEventOriginEnum.Internal : CommonEventOriginEnum.External,
    type: event.type,
    thumbnail: event.thumbnail,
    status: event.status as any,
    date: convertDate(event.startDate),
    scheduleStart: convertDate(event.startDate),
    scheduleEnd: convertDate(event.endDate),
    organizationName: event.organizationName,
    organizationId: event.organizationId,
    online: event.online,
    salonUrl: event.salonUrl,
    geoDistance: event._rankingInfo?.geoDistance,
  };
}

export function opsEventToCommonEvent(event: OpsEvent, eventTypes: EventType[]): CommonEvent {
  const eventType = eventTypes.find((e) => e.id === event.type);
  return {
    id: event.id,
    name: event?.publicName
      ? event.publicName
      : event?.location
      ? `${eventType!.name} à ${event.location.city}`
      : eventType!.name,
    status: event.status as any,
    type: event.type,
    location: event.location ? new City(event.location) : undefined,
    areas: event.areas && event.areas.length ? event.areas.map((area) => new City(area)) : undefined,
    date: convertDate(event.date),
    scheduleStart: convertDate(event.scheduleStart),
    scheduleEnd: convertDate(event.scheduleEnd),
    metaDescription: event.metaDescription,
    metaTitle: event.metaTitle,
    smallSocialNetworkFileId: event.smallSocialNetworkFileId,
    bigSocialNetworkFileId: event.bigSocialNetworkFileId,
    thumbnail: event.thumbnailFileId,
    origin: CommonEventOriginEnum.Internal,
    jobs: event.jobs,
    jobsTitles: event.jobsTitles,
    seo: true,
    online: event.online,
    salonUrl: event.salonUrl,
    organization: {
      id: event.wizCompanyId,
    },
  };
}

export function opsEventToCommonListEvent(event: OpsEventLight): CommonListEvent {
  return {
    id: event.id,
    name: event.publicName,
    location: event.location ? new City(event.location) : undefined,
    areas: event.areas && event.areas.length ? event.areas.map((area) => new City(area)) : undefined,
    origin: CommonEventOriginEnum.Internal,
    type: event.type,
    thumbnail: event.thumbnailFileId,
    status: event.status as any,
    date: convertDate(event.date),
    scheduleStart: convertDate(event.scheduleStart),
    scheduleEnd: convertDate(event.scheduleEnd),
    online: event.online,
    salonUrl: event.salonUrl,
  };
}

export function eventToCommonListEvent(event: Event): CommonListEvent {
  return {
    id: event.id,
    name: event.name,
    location: event.location ? new City(event.location) : undefined,
    areas: event.areas && event.areas.length ? event.areas.map((area) => new City(area)) : undefined,
    origin: CommonEventOriginEnum.External,
    type: event.type,
    thumbnail: event.thumbnail,
    status: event.status as any,
    date: convertDate(event.startDate),
    scheduleStart: convertDate(event.startDate),
    scheduleEnd: convertDate(event.endDate),
    online: event.online,
  };
}
