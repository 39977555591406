<section
  class="container"
  [class.container--without-shadow]="withoutShadowHeader"
  [class.container--full-width]="isFullWidth"
  [ngStyle]="transitionStyle"
>
  <button
    class="burger-btn"
    [id]="openMenuButtonId"
    [class.burger-btn--fullscreen]="(displayFullscreen$ | async) === true"
    type="button"
    (click)="displayMenu(true)"
    aria-label="Ouvrir le menu"
  >
    <mat-icon svgIcon="burger" role="presentation"></mat-icon>
  </button>

  <a
    class="header-logo"
    [attr.fullscreen]="(displayFullscreen$ | async) === true"
    [routerLink]="['/']"
    (click)="trackEvent(eventCategory, 'Click Logo')"
  >
    <img
      *ngIf="(displayFullscreen$ | async) === false && !isWhiteHeader"
      [attr.fullscreen]="(displayFullscreen$ | async) === true"
      [src]="logoWhite$ | async"
      alt="Logo de {{ collectivityName$ | async }}"
      loading="lazy"
    />
    <img
      *ngIf="(displayFullscreen$ | async) === true || isWhiteHeader"
      [attr.fullscreen]="(displayFullscreen$ | async) === true"
      [src]="logoSecondary$ | async"
      [attr.alt]="collectivityName$ | async"
      loading="lazy"
    />
  </a>

  <a
    *ngIf="(isConnected$ | async) === false"
    class="btn sign__up--mobile"
    aria-label="S'inscrire"
    [routerLink]="['/', FeaturesRoutingEnum.Signup]"
    >S'inscrire</a
  >

  <div class="agenda">
    <app-events-agenda [isInHeader]="true"></app-events-agenda>

    <ng-container>
      <ul
        class="sign"
        [class.sign--primaryContrast]="(displayFullscreen$ | async) === false && !isWhiteHeader"
        *ngIf="isBrowser && (profile$ | async) === null && !(jwtState$ | async)?.tokens"
      >
        <li>
          <a class="btn sign__up" aria-label="S'inscrire" [routerLink]="['/', FeaturesRoutingEnum.Signup]"
            >S'inscrire</a
          >
        </li>
        <li>
          <a class="btn-clean sign__in" aria-label="Se connecter" [routerLink]="['/', FeaturesRoutingEnum.Signin]"
            >Se connecter</a
          >
        </li>
      </ul>

      <div *ngIf="profile$ | async as profile">
        <app-user-dropdown
          [userName]="profile.firstName + ' ' + profile.lastName"
          [roles]="roles$ | async"
          [extranetUrl]="extranetUrl$ | async"
        ></app-user-dropdown>
      </div>
    </ng-container>
  </div>

  <button class="btn-clean avoid-link" ngxScrollTo="#mainContent" [ngxScrollToOffset]="0">Aller au contenu</button>

  <ng-container
    *ngIf="(displayFullscreen$ | async) === false && (hideEllipses$ | async) === false && isHeaderStuck === false"
  >
    <ng-container *ngIf="isBrowser">
      <div class="ellipse__first" [@fadeInOut]>
        <mat-icon svgIcon="ellipse1" role="presentation" [inline]="true"></mat-icon>
      </div>
      <div class="ellipse__second" [@fadeInOut]>
        <mat-icon svgIcon="ellipse2" role="presentation" [inline]="true"></mat-icon>
      </div>
      <div class="ellipse__third" [@fadeInOut]>
        <mat-icon svgIcon="ellipse3" role="presentation" [inline]="true"></mat-icon>
      </div>
    </ng-container>

    <ng-container *ngIf="!isBrowser">
      <div class="ellipse__first">
        <mat-icon svgIcon="ellipse1" role="presentation" [inline]="true"></mat-icon>
      </div>
      <div class="ellipse__second">
        <mat-icon svgIcon="ellipse2" role="presentation" [inline]="true"></mat-icon>
      </div>
      <div class="ellipse__third">
        <mat-icon svgIcon="ellipse3" role="presentation" [inline]="true"></mat-icon>
      </div>
    </ng-container>
  </ng-container>

  <app-burger-menu-main
    [isOpen]="(isMenuOpen$ | async)!"
    (closeMenu)="displayMenu(false)"
    class="menu"
    [class.menu--open]="isMenuOpen$ | async"
    [mainContainerClass]="mainContainerClass"
    [menuItems]="(fullMenuItems$ | async)!"
    [menuItemId]="menuItemId"
    [openMenuButtonId]="openMenuButtonId"
    [currentNestedNavigation]="(currentNestedNavigation$ | async)!"
    [eventCategory]="eventCategory"
  ></app-burger-menu-main>
</section>
