import { ConsentInterface } from '@wizbii/models';

type Consents = Record<string, ConsentInterface[]>;

export class SetConsents {
  static readonly type = '[Consents] Set Consents';
  constructor(public consents: Consents | { [key: string]: boolean } | ConsentInterface[]) {}
}

export class SetConsent {
  static readonly type = '[Consents] Set Consent';
  constructor(public consent: ConsentInterface) {}
}
