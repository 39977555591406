import { CollectivityInterface } from '@b2b/models';
import { getCollectivityAnalytiksDomain } from '@core/utils/collectivity-utils';
import { environment } from '@environment';
import { createWizbiiAnalyticsConfig, TrackingConfig } from '@wizbii/tracking';
import deepmerge from 'deepmerge';

export const defaultConsentLabels = {};

export const defaultInformationGroupKeys = ['technical'];

export const trackingConfig = (collectivity: CollectivityInterface): TrackingConfig => ({
  uuidSetterDomain: `https://a.${getCollectivityAnalytiksDomain(collectivity)}`,
  canDisableWizbiiAnalytiks: true,
  consentWidget: {
    version: environment.platform !== 'prod' ? 'staging' : '',
    apiDomain: environment.domain.wizbii,
    wizbiiApiDomain: environment.domain.wizbii,
    enableTermsModal: false,
    locale: 'fr',
    productId: `collectivity-${collectivity.id}`,
    productLabel: `${collectivity.productName.toUpperCase()}`,
    defaultValue: false,
    labels: deepmerge(defaultConsentLabels, collectivity.configuration.consent?.labels ?? {}),
    legalUrlBanner: `https://${collectivity.domain}/confidentialite`,
    rejectAll: true,
  },
  trackers: {
    analytics_wizbii: createWizbiiAnalyticsConfig(
      collectivity.configuration.trackers.googleAnalyticsId,
      `https://a.${getCollectivityAnalytiksDomain(collectivity)}`
    ),
  },
  informationGroupKeys: [
    ...new Set([...defaultInformationGroupKeys, ...(collectivity.configuration.consent?.informationGroupKeys ?? [])]),
  ],
});
