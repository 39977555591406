import { InjectionToken } from '@angular/core';

export interface CollectivityWebservicesTokenInterface {
  collectivityDomain: string;
}

export const COLLECTIVITY_WEBSERVICES_TOKEN = new InjectionToken<CollectivityWebservicesTokenInterface>(
  'Collectivite Webservices Token',
  {
    providedIn: 'root',
    factory: () => ({
      collectivityDomain: '',
    }),
  }
);
