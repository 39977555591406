import { ProfileInterface } from '@models/profile/profile';

export class ProfileGet {
  static readonly type = '[Profile] Get';

  constructor(readonly id: string) {}
}

export class ProfileRefresh {
  static readonly type = '[Profile] Refresh';
}

export class ProfilePut {
  static readonly type = '[Profile] Put';

  constructor(readonly profile: Partial<ProfileInterface>) {}
}

export class ProfileDelete {
  static readonly type = '[Profile] Delete';
}
