<div class="mat-menu__flex">
  <button mat-button class="mat-menu__btn" [matMenuTriggerFor]="userMenu">
    {{ userName }}<mat-icon class="mat-menu__btn__icon" svgIcon="chevron_down" role="presentation"></mat-icon>
  </button>
</div>

<mat-menu class="mat-menu" #userMenu="matMenu">
  <ng-template matMenuContent>
    <div [style.width.px]="userMenuWidth">
      <a
        class="mat-menu__item"
        aria-label="Accédez à mon compte"
        mat-menu-item
        [routerLink]="['/', FeaturesRoutingEnum.User, UserRoutingEnum.Profile]"
        (click)="closeMenu?.emit('Click Profile')"
        >Mon compte</a
      >
      <a
        *ngIf="roles && (roles | include : EXTRANET_ROLES)"
        class="mat-menu__item"
        mat-menu-item
        [href]="'https://' + extranetUrl"
        target="_blank"
      >
        Administration</a
      >

      <button class="mat-menu__item" aria-label="Accédez à mes déconnexion" mat-menu-item (click)="logout()">
        Déconnexion
      </button>
    </div>
  </ng-template>
</mat-menu>
