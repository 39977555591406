import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobB2BWebservice } from '@webservices/jobs/job-b2b.webservice';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

@Injectable()
export class PoleEmploiInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  token$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private readonly jobB2BWebservice: JobB2BWebservice) {}

  addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.token$.pipe(
      take(1),
      switchMap((token: string | null) => {
        if (token && request.url.includes('api.emploi-store')) {
          return next.handle(this.addToken(request, token)).pipe(
            catchError((error) => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.refreshToken(request, next);
              }
              return throwError(error);
            })
          );
        }

        if (!token && request.url.includes('api.emploi-store')) {
          return this.refreshToken(request, next);
        }

        return next.handle(request);
      })
    );
  }

  refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.token$.next(null);

      return this.jobB2BWebservice.getAccessToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.token$.next(newToken);
            return next.handle(this.addToken(request, newToken));
          }

          // If we don't get a new token, we are in trouble so logout.
          return throwError('Error getting token');
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    }

    return this.token$.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token: string | null) => {
        return next.handle(this.addToken(request, token as string));
      })
    );
  }
}
