<div class="agenda__flex">
  <button
    mat-button
    type="button"
    class="agenda__trigger"
    [class.agenda__notify]="notifyEvents"
    (click)="toggleCalendar()"
    aria-label="Calendrier des événements"
  >
    <mat-icon class="agenda__trigger__icon" svgIcon="calendar2" role="presentation"></mat-icon>
  </button>

  <app-events-calendar
    class="agenda__calendar"
    [class.agenda__calendar--header]="isInHeader"
    *ngIf="(showCalendar$ | async) === true"
    (appClickOutside)="closeCalendar($event, true)"
    (closeCalendar)="closeCalendar($event, false)"
  ></app-events-calendar>
</div>
