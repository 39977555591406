import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { ServiceInterface } from '@b2b/models';
import { BurgerMenuMainModule } from '@commons/burger-menu/burger-menu-main/burger-menu-main.component';
import { contactMenuItem, homeMenuItem } from '@commons/burger-menu/menu-items';
import { EventsAgendaModule } from '@domains/events/events-agenda/events-agenda.component';
import { UserDropdownModule } from '@domains/user-dropdown/user-dropdown.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { MenuItem, SubItem } from '@models/menu/menu-item';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { JwtState, JwtStateInterface } from '@stores/jwt/jwt.state';
import { ProfileState, ProfileStateModel } from '@stores/profile/profile.state';
import { trackEvent } from '@wizbii/tracking';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss',
    './header-menu.component.scss',
    './header-avoid-link.component.scss',
    './header-ellipse.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('.2s cubic-bezier(.645, .045, .355, 1)')]),
      transition(':leave', [animate('.2s cubic-bezier(.645, .045, .355, 1)', style({ opacity: 0 }))]),
    ]),
  ],
})
export class HeaderComponent implements OnDestroy, OnChanges {
  FeaturesRoutingEnum = FeaturesRoutingEnum;

  @ViewChild(MatMenuTrigger, { read: ElementRef })
  userMenu!: ElementRef<HTMLElement>;

  get userMenuWidth(): number {
    return this.userMenu?.nativeElement?.clientWidth;
  }

  @Input()
  set displayFullscreen(displayFullscreen: boolean) {
    this.displayFullscreen$.next(!!displayFullscreen);
  }
  @Input()
  set hideEllipses(hideEllipses: boolean) {
    this.hideEllipses$.next(hideEllipses);
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  @Input() isHeaderStuck = false;
  @Input() noStickyHeader = false;
  @Input() isWhiteHeader = false;
  @Input() withoutShadowHeader = false;
  @Input() isFullWidth = false;

  readonly mainContainerClass = 'main-container';
  readonly menuItemId = 'menu-item';
  readonly openMenuButtonId = 'burger-menu-open-button';
  readonly eventCategory = 'Navigation';
  readonly trackEvent = trackEvent;

  @Select(CollectivityState.collectivityName)
  collectivityName$!: Observable<string>;

  @Select(CollectivityState.extranetUrl)
  extranetUrl$!: Observable<string>;

  @Select(CollectivityState.logoWhite)
  logoWhite$!: Observable<string>;

  @Select(CollectivityState.logoSecondary)
  logoSecondary$!: Observable<string>;

  @Select(CollectivityState.services)
  services$!: Observable<ServiceInterface[]>;

  @Select(JwtState)
  jwtState$!: Observable<JwtStateInterface>;

  @Select(JwtState.roles)
  roles$!: Observable<string[]>;

  @Select(JwtState.isConnected)
  isConnected$!: Observable<boolean>;

  @Select(ProfileState)
  profile$!: Observable<ProfileStateModel>;

  fullMenuItems$ = new BehaviorSubject<MenuItem[]>([]);
  isMenuOpen$ = new BehaviorSubject<boolean>(false);
  displayFullscreen$ = new BehaviorSubject<boolean>(false);
  hideEllipses$ = new BehaviorSubject<boolean>(false);
  currentNestedNavigation$ = new BehaviorSubject<MenuItem | undefined>(undefined);

  transitionStyle = {};

  constructor(
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
  ) {
    // add services from conf to burger menu
    this.services$
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
        map((services) => ({
          services: services.filter((service) => service.subItem),
          filteredMenuEntries: services
            .filter((service) => !service.subItem)
            .map(
              (service) =>
                ({
                  label: service.menuLabel,
                  url: service.link,
                  eventAction: service.eventAction,
                  redirect: service.redirect,
                } as MenuItem)
            ),
        })),
        map(({ services, filteredMenuEntries }) => ({
          subItems: services.reduce(
            (acc: SubItem[], service: ServiceInterface) => [
              ...acc,
              {
                label: service.title,
                url: service.link,
                eventAction: service.eventAction,
                redirect: service.redirect,
              } as SubItem,
            ],
            []
          ),
          filteredMenuEntries: filteredMenuEntries,
        })),
        switchMap(({ subItems, filteredMenuEntries }) => {
          return of([
            homeMenuItem,
            ...filteredMenuEntries,
            {
              label: 'Je profite des services offerts',
              subItems: subItems,
              eventAction: 'Click Services',
            },
            contactMenuItem,
          ]);
        })
      )
      .subscribe(this.fullMenuItems$);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // for drive and money pages with header not sticky
    // disable transition animation (essentially for padding animation)
    if (changes?.noStickyHeader?.currentValue) {
      this.transitionStyle = {
        transition: 'none',
      };
      // enable transition animation
      // if noStickyHeader input changes and equals false
    } else if (changes && changes.noStickyHeader && changes.noStickyHeader.currentValue === false) {
      this.transitionStyle = {};
    }
  }

  displayMenu(display: boolean): void {
    window.setTimeout(() => {
      (document.querySelector(`#${this.menuItemId}-0`) as HTMLElement)?.focus();
    });
    this.currentNestedNavigation$.next(
      this.fullMenuItems$
        .getValue()
        .find((menuItem) => menuItem.subItems?.find((subItem) => subItem.url === this.router.url.split('/')[1]))
    );
    disableBodyScroll(document.querySelector(`.${this.mainContainerClass}`) || ('' as any));
    this.isMenuOpen$.next(display);
    this.trackEvent(this.eventCategory, display ? 'Menu Open' : 'Menu Close');
  }

  ngOnDestroy(): void {
    this.isMenuOpen$.complete();
    this.displayFullscreen$.complete();
    clearAllBodyScrollLocks();
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    BurgerMenuMainModule,
    ScrollToModule,
    MatIconModule,
    UserDropdownModule,
    EventsAgendaModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
