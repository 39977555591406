import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ToasterNotification, ToasterService } from '@core/services/toaster/toaster.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [
    trigger('animateToasts', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate('0.5s ease-in-out')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit, OnDestroy {
  private readonly destroyed$: Subject<void> = new Subject();
  component$: ReplaySubject<ToasterNotification | undefined> = new ReplaySubject<ToasterNotification | undefined>();

  constructor(private readonly toasterService: ToasterService) {}

  ngOnInit(): void {
    this.toasterService.notification.pipe(takeUntil(this.destroyed$)).subscribe((notification: ToasterNotification) => {
      this.component$.next(notification);
    });

    this.toasterService.clearToast.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.component$.next(undefined);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

@Pipe({ name: 'isComponentPortal', pure: true })
export class isComponentPortalPipe implements PipeTransform {
  transform(value: any): boolean {
    return value instanceof ComponentPortal;
  }
}

@Pipe({
  name: 'castToComponentPortal',
  pure: true,
})
export class CastToComponentPortalPipe implements PipeTransform {
  transform(value: any): ComponentPortal<any> {
    return value;
  }
}

@NgModule({
  imports: [CommonModule, PortalModule],
  exports: [ToasterComponent],
  declarations: [ToasterComponent, isComponentPortalPipe, CastToComponentPortalPipe],
})
export class ToasterModule {}
