export function convertDate(date?: string | number | Date): Date | undefined {
  return date
    ? new Date(
        typeof date === 'string'
          ? date.replace(/\+([0-9]{2}):?([0-9]{2})/g, '+$1:$2')
          : typeof date === 'number'
          ? new Date(date * 1000)
          : date
      )
    : undefined;
}
