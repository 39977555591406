<article class="card-event">
  <div class="card-event__illu">
    <div class="card-event__illu__bg" [style.background-image]="'url(' + imgSrc + ')'"></div>
  </div>
  <div class="card-event__content">
    <p class="card-event__name">
      {{ event?.name }}
    </p>
    <p class="card-event__date">{{ event.date | date : 'EEE d MMMM yyyy' }}</p>
  </div>
</article>
