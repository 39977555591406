import { CommonModule } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, NgModule, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output()
  appClickOutside = new EventEmitter<MouseEvent>();

  constructor(private readonly _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.appClickOutside.emit(event);
    }
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [ClickOutsideDirective],
  declarations: [ClickOutsideDirective],
})
export class ClickOutsideDirectiveModule {}
