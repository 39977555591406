/* eslint-disable sonarjs/no-duplicate-string */
import { Inject, Injectable, Optional, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { CollectivityInterface } from '@b2b/models';
import { getDescription, getTitle } from '@core/utils/seo-utils';
import { MetaData } from '@models/meta/meta-data';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Store } from '@ngxs/store';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { Request } from 'express';

@Injectable({ providedIn: 'root' })
export class CustomMetaService {
  private readonly renderer: Renderer2;
  private link: any;

  private readonly defaultRobots = 'index,follow';

  constructor(
    private readonly metaService: Meta,
    private readonly titleService: Title,
    private readonly rendererFactory: RendererFactory2,
    private readonly store: Store,
    @Optional() @Inject(REQUEST) private readonly request: Request
  ) {
    this.renderer = this.rendererFactory.createRenderer(document, {
      id: Date.now().toString(),
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
  }

  private addOtherTags(collectivity: CollectivityInterface): void {
    this.updateOrAddTags([
      { property: 'og:type', content: 'siteweb' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:creator', content: `@${collectivity.configuration.seo.twitterAccount}` },
    ]);
  }

  updateForSeo({ title, description, robotValue, imgSmall, imgBig, altImg }: MetaData): void {
    const defaultTitle: string = this.store.selectSnapshot(CollectivityState.defaultSEOTitle) as string;
    const defaultDescription: string = this.store.selectSnapshot(CollectivityState.defaultSEODescription) as string;
    const defaultImageAlt: string = this.store.selectSnapshot(CollectivityState.collectivityName) as string;
    const defaultSEOOgImageSquare: string = this.store.selectSnapshot(CollectivityState.defaultSEOOgImageSquare)
      ?.url as string;
    const defaultSEOOgImageRec: string = this.store.selectSnapshot(CollectivityState.defaultSEOOgImageRec)
      ?.url as string;

    this.updateTitle(title ?? defaultTitle);
    this.updateDescription(description ?? defaultDescription);
    this.updateRobots(robotValue ?? this.defaultRobots);
    this.updateImg(imgSmall ?? defaultSEOOgImageSquare, imgBig ?? defaultSEOOgImageRec, altImg ?? defaultImageAlt);
  }

  restoreForSeo(): void {
    this.restoreTitle();
    this.restoreDescription();
    this.restoreRobots();
    this.restoreImg();
    this.updateOrAddTags([{ property: 'og:type', content: 'siteweb' }]);
  }

  initSeo(collectivity: CollectivityInterface): void {
    this.updateForSeo({
      title: collectivity.configuration.seo.metaTitle ?? getTitle(collectivity.configuration.seo.cityName),
      description:
        collectivity.configuration.seo.metaDescription ??
        getDescription(collectivity.configuration.crmData.citizen.inclusive),
      robotValue: this.defaultRobots,
      imgBig: collectivity.configuration.seo.ogImageRec.url,
      imgSmall: collectivity.configuration.seo.ogImageSquare.url,
      altImg: collectivity.name,
    });
    this.addOtherTags(collectivity);
  }

  private updateTitle(title: string): void {
    this.titleService.setTitle(title);
    this.updateOrAddTags([
      { property: 'og:title', content: title },
      { name: 'title', content: title },
      { name: 'twitter:title', content: title },
    ]);
  }

  private restoreTitle(): void {
    this.updateTitle(this.store.selectSnapshot(CollectivityState.defaultSEOTitle) as string);
  }

  private updateRobots(value: string): void {
    this.updateOrAddTag({ name: 'robots', content: value });
  }

  private restoreRobots(): void {
    this.updateRobots(this.defaultRobots);
  }

  private updateDescription(description: string): void {
    this.updateOrAddTags([
      { name: 'description', content: description },
      { property: 'og:description', content: description },
      { name: 'twitter:description', content: description },
    ]);
  }

  private restoreDescription(): void {
    this.updateDescription(this.store.selectSnapshot(CollectivityState.defaultSEODescription) as string);
  }

  private updateImg(imgSmall?: string, imgBig?: string, altImg?: string): void {
    this.updateOrAddTags([
      ...(imgBig
        ? [
            { id: 'og:image-1', property: 'og:image', content: imgBig },
            { id: 'og:image:secure_url-1', property: 'og:image:secure_url', content: imgBig },
            ...(altImg ? [{ id: 'og:image:alt-1', property: 'og:image:alt', content: altImg }] : []),
          ]
        : []),
      ...(imgSmall
        ? [
            { id: 'og:image-2', property: 'og:image', content: imgSmall },
            { id: 'og:image:secure_url-2', property: 'og:image:secure_url', content: imgSmall },
            ...(altImg ? [{ id: 'og:image:alt-1', property: 'og:image:alt', content: altImg }] : []),
          ]
        : []),
      ...(imgBig ? [{ id: 'meta:image-1', itemprop: 'image', content: imgBig }] : []),
      ...(imgSmall ? [{ id: 'meta:image-2', itemprop: 'image', content: imgSmall }] : []),
      ...(imgSmall ? [{ id: 'meta:image-twitter', name: 'twitter:image', content: imgSmall }] : []),
    ]);
  }

  private restoreImg(): void {
    const defaultImageAlt: string = this.store.selectSnapshot(CollectivityState.collectivityName) as string;
    const defaultSEOOgImageSquare: string = this.store.selectSnapshot(CollectivityState.defaultSEOOgImageSquare)
      ?.url as string;
    const defaultSEOOgImageRec: string = this.store.selectSnapshot(CollectivityState.defaultSEOOgImageRec)
      ?.url as string;
    this.updateImg(defaultSEOOgImageSquare, defaultSEOOgImageRec, defaultImageAlt);
  }

  updateOrAddTag(meta: MetaDefinition): void {
    if (!meta) {
      return;
    }

    const id = Object.keys(meta)[0];

    if (Object.keys(meta).includes('id') && this.metaService.getTag(`${id}="${meta[id]}"`)) {
      this.metaService.updateTag(meta, `${id}="${meta[id]}"`);
      return;
    }

    const key = Object.keys(meta)[0];
    if (this.metaService.getTag(`${key}="${meta[key]}"`)) {
      this.metaService.updateTag(meta, `${key}="${meta[key]}"`);
      return;
    }

    this.metaService.addTag(meta);
  }

  updateOrAddTags(metas: MetaDefinition[]): void {
    if (!metas) {
      return;
    }

    metas.forEach((meta) => {
      this.updateOrAddTag(meta);
    });
  }

  deleteTag(meta: MetaDefinition): void {
    if (!meta) {
      return;
    }

    const key = Object.keys(meta)[0];

    this.metaService.removeTag(`${key}="${meta[key]}"`);
  }

  deleteTags(metas: MetaDefinition[]): void {
    if (!metas) {
      return;
    }

    return metas.forEach((meta) => {
      this.deleteTag(meta);
    });
  }

  updateOrAddCanonicalURL(url?: string): void {
    const canUrl = url
      ? url
      : this.request
      ? [this.request.protocol, '//', this.request.hostname, this.request.path].join('')
      : [window.location.protocol, '//', window.location.host, window.location.pathname].join('');

    if (!this.link) {
      this.link = this.renderer.createElement('link');
      this.link.setAttribute('rel', 'canonical');
      this.link.setAttribute('href', canUrl);
      this.renderer.appendChild(document.head, this.link);
      return;
    }

    this.link.setAttribute('href', canUrl);
  }

  deleteCanonicalURL(): void {
    if (!this.link) {
      return;
    }

    this.renderer.removeChild(document.head, this.link);
  }
}
