import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { COLLECTIVITY_WEBSERVICES_TOKEN, CollectivityWebservicesTokenInterface } from '@webservices/injection-tokens';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class JobB2BWebservice {
  private readonly url: string;

  constructor(
    @Inject(COLLECTIVITY_WEBSERVICES_TOKEN)
    collectivityWebservicesToken: CollectivityWebservicesTokenInterface,
    private readonly http: HttpClient
  ) {
    this.url = `${environment.api.collectivityPrefix}.${collectivityWebservicesToken.collectivityDomain}`;
  }

  getAccessToken(): Observable<string> {
    return this.http
      .get<{ token: { accessToken: string } }>(`${this.url}/v1/pole-emploi/auth`)
      .pipe(map((payload) => payload.token.accessToken));
  }
}
