import { CollectivityInterface } from '@b2b/models';
import { environment } from '@environment';

export const provideCollectivityFactory = (fn: (collectivity: CollectivityInterface) => any) => {
  return (): any => fn((window as { [key: string]: any })['collectivity']);
};

export const getCollectivityDomain = (collectivity: CollectivityInterface): string => {
  const collectivityDomainSplitted = collectivity.domain.split('.');
  collectivityDomainSplitted.shift();
  return environment.production ? collectivityDomainSplitted.join('.') : `pau.collectivite.staging.k8s.pouteam.us`;
};

/**
 * A new platform without specific domain is deployed on xxx.collectivite.wizbii.com
 * We must remove the substring collectivite to call the right analytiks uri : a.wizbii.com
 */
export const getCollectivityAnalytiksDomain = (collectivity: CollectivityInterface): string => {
  const collectivityDomainSplit = getCollectivityDomain(collectivity).split('.');

  if (collectivityDomainSplit[0] === 'collectivite') {
    collectivityDomainSplit.shift();
  }

  return collectivityDomainSplit.join('.');
};
