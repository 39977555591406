import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { JwtState } from '@stores/jwt/jwt.state';
import { GetUserInfo, Init, PatchUserWithService } from '@stores/user/user.actions';
import { UserOverview } from '@wizbii/models';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { filter, Observable, of, switchMap, tap } from 'rxjs';

export type UserStateModel = UserOverview | null;

@State<UserStateModel>({
  name: 'user',
  defaults: null,
})
@Injectable()
export class UserState implements NgxsOnInit {
  constructor(private readonly authWebservice: AuthenticationWebservice, private readonly store: Store) {}

  ngxsOnInit(_: StateContext<UserStateModel>): void {
    this.store.dispatch(new Init());
  }

  @Action(Init)
  init(ctx: StateContext<UserStateModel>): Observable<void> {
    return this.store.select(JwtState.userId).pipe(
      tap((userId) => !userId && ctx.setState(null)),
      filter((userId) => !!userId),
      switchMap((userId) => ctx.dispatch(new GetUserInfo(userId ?? '')))
    );
  }

  @Action(GetUserInfo)
  getUserInfo(ctx: StateContext<UserStateModel>, { userId }: GetUserInfo): Observable<UserOverview> {
    return this.authWebservice.getUserOverview(userId).pipe(tap((userOverview) => ctx.patchState(userOverview)));
  }

  @Action(PatchUserWithService)
  patchUserWithService(
    ctx: StateContext<UserStateModel>,
    { serviceId }: PatchUserWithService
  ): Observable<UserStateModel> | null {
    return of(
      ctx.patchState({
        activatedServices: [
          ...(ctx.getState()?.activatedServices ?? []),
          { id: serviceId, activationDate: new Date() },
        ],
      })
    );
  }
}
