<form
  class="form"
  *ngIf="collectivityId$ | async as collectivityId"
  [formGroup]="form"
  (ngSubmit)="handleSubmit(form, collectivityId)"
>
  <h1 class="form__title">Connexion</h1>

  <mat-label class="form__label">Adresse email</mat-label>
  <mat-form-field class="form__field">
    <input type="email" matInput formControlName="email" aria-label="Email" />
  </mat-form-field>

  <mat-label class="form__label">Mot de passe</mat-label>
  <mat-form-field class="form__field">
    <input type="password" matInput formControlName="password" aria-label="Mot de passe" />
  </mat-form-field>

  <div class="form__actions">
    <a
      [routerLink]="['/', FeaturesRoutingEnum.Signin, SignInRoutingEnum.ForgotPassword]"
      queryParamsHandling="preserve"
      class="form__actions__forgot"
      aria-label="Mot de passe oublié ?"
      >Mot de passe oublié&nbsp;?</a
    >
  </div>

  <mat-error class="form__error" *ngIf="(error$ | async) === true"
    >Votre email et/ou votre mot de passe sont invalides</mat-error
  >

  <button type="submit" class="btn form__submit" aria-label="Bouton connexion">Me connecter</button>

  <p class="form__signUp">
    Vous n'avez pas de compte sur {{ collectivityDomain$ | async }} ?
    <a [routerLink]="['/', FeaturesRoutingEnum.Signup]" queryParamsHandling="preserve" aria-label="Inscrivez vous"
      >Inscrivez-vous&nbsp;!</a
    >
  </p>
</form>
