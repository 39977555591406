import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Store } from '@ngxs/store';
import { JwtState, JwtStateInterface } from '@stores/jwt/jwt.state';
import { filter, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (isPlatformServer(this.platformId) || !!route.queryParamMap?.get('login-token')) {
      return of(true);
    }

    return this.store.select(JwtState).pipe(
      filter((jwtState) => !!jwtState),
      map((jwtState: JwtStateInterface) =>
        jwtState.tokens ? true : this.router.parseUrl(route.data.presentationRoute ?? `/${FeaturesRoutingEnum.Signin}`)
      )
    );
  }
}
