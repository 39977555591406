import { Injectable } from '@angular/core';
import {
  CollectivityInterface,
  ContactInterface,
  ContactPageInterface,
  CRMDataInterface,
  HomepageArticleInterface,
  HomepageCityBusInterface,
  HomepageDiscoverCityInterface,
  HomepageDriveInterface,
  HomepageEngagementInterface,
  HomepageEventInterface,
  HomepageInterface,
  HomepageJobInterface,
  HomepageMoneyInterface,
  HomepageNewsInterface,
  ImageInterface,
  JoinUsInterface,
  LinkInterface,
  NewsListInterface,
  SEOInterface,
  ServiceInterface,
  Social,
} from '@b2b/models';
import { getDescription, getTitle } from '@core/utils/seo-utils';
import { AvailableLegal, availableLegalsMap } from '@models/legal/legal-page-map';
import { NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';

export type CollectivityStateModel = CollectivityInterface | null;
const CollectivityStateToken = new StateToken<CollectivityStateModel>('collectivity');

@State<CollectivityStateModel>({
  name: CollectivityStateToken,
  defaults: null,
})
@Injectable()
export class CollectivityState implements NgxsOnInit {
  @Selector([CollectivityState])
  static collectivity(state: CollectivityStateModel): CollectivityStateModel {
    return state;
  }

  @Selector([CollectivityState])
  static id(state: CollectivityStateModel): string | undefined {
    return state?.id;
  }

  @Selector([CollectivityState])
  static collectivityName(state: CollectivityStateModel): string | undefined {
    return state?.name;
  }

  @Selector([CollectivityState])
  static collectivityUrl(state: CollectivityStateModel): string | undefined {
    return state?.url;
  }

  @Selector([CollectivityState])
  static formattedDomain(state: CollectivityStateModel): string | undefined {
    return state?.domain?.replace('www.', '');
  }

  @Selector([CollectivityState])
  static extranetUrl(state: CollectivityStateModel): string | undefined {
    return state?.extranetUrl;
  }

  @Selector([CollectivityState])
  static logoWhite(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.logos.logoWhite.url;
  }

  @Selector([CollectivityState])
  static logoSecondary(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.logos.logoSecondary.url;
  }

  @Selector([CollectivityState])
  static services(state: CollectivityStateModel): ServiceInterface[] | undefined {
    return state?.configuration.services;
  }

  @Selector([CollectivityState])
  static socials(state: CollectivityStateModel): Social[] | undefined {
    return state?.configuration.socials;
  }

  @Selector([CollectivityState])
  static contact(state: CollectivityStateModel): ContactInterface | undefined {
    return state?.configuration.contact;
  }

  @Selector([CollectivityState])
  static menuHero1(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.heroes.menuHero1.url;
  }

  @Selector([CollectivityState])
  static menuHero2(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.heroes.menuHero2.url;
  }

  @Selector([CollectivityState])
  static menuHero3(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.heroes.menuHero3.url;
  }

  @Selector([CollectivityState])
  static homepageDiscoverCityImage(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.heroes.homepageDiscoverCity.url;
  }

  @Selector([CollectivityState])
  static iconTouch(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.pictos.iconTouch.url;
  }

  @Selector([CollectivityState])
  static iconNoResult(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.pictos.iconNoResult.url;
  }

  @Selector([CollectivityState])
  static homepageHero(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.heroes.homepageHero.url;
  }

  @Selector([CollectivityState])
  static signInIllustration(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.pages.signInIllustration.url;
  }

  @Selector([CollectivityState])
  static signUpAddressIllustration(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.pages.signUpAddressIllustration.url;
  }

  @Selector([CollectivityState])
  static signUpInfosIllustration(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.pages.signUpInfosIllustration.url;
  }

  @Selector([CollectivityState])
  static signUpPasswordIllustration(state: CollectivityStateModel): string | undefined {
    return state?.configuration.assets.pages.signUpPasswordIllustration.url;
  }

  @Selector([CollectivityState])
  static bottomLeftLogo(state: CollectivityStateModel): ImageInterface | undefined {
    return state?.configuration.assets.footer.bottomLeftLogo;
  }

  @Selector([CollectivityState])
  static bottomRightLogo(state: CollectivityStateModel): ImageInterface | undefined {
    return state?.configuration.assets.footer.bottomRightLogo;
  }

  @Selector([CollectivityState])
  static copyright(state: CollectivityStateModel): string | undefined {
    return state?.configuration.footer.copyright;
  }

  @Selector([CollectivityState])
  static cities(state: CollectivityStateModel): string[] {
    return [...(state?.cities ?? [])].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
  }

  @Selector([CollectivityState])
  static homepage(state: CollectivityStateModel): HomepageInterface | undefined {
    return state?.configuration.homepage;
  }

  @Selector([CollectivityState])
  static homepageEvent(state: CollectivityStateModel): HomepageEventInterface | undefined {
    return state?.configuration.homepage?.event;
  }

  @Selector([CollectivityState])
  static homepageEngagement(state: CollectivityStateModel): HomepageEngagementInterface | undefined {
    return state?.configuration.homepage?.engagement;
  }

  @Selector([CollectivityState])
  static homepageArticle(state: CollectivityStateModel): HomepageArticleInterface | undefined {
    return state?.configuration.homepage?.article;
  }

  @Selector([CollectivityState])
  static jobs(state: CollectivityStateModel): HomepageJobInterface | undefined {
    return state?.configuration.homepage?.jobs;
  }

  @Selector([CollectivityState])
  static news(state: CollectivityStateModel): NewsListInterface[] | undefined {
    return state?.news;
  }

  @Selector([CollectivityState])
  static homepageNews(state: CollectivityStateModel): HomepageNewsInterface | undefined {
    return state?.configuration.homepage.news;
  }

  @Selector([CollectivityState])
  static joinUs(state: CollectivityStateModel): JoinUsInterface | undefined {
    return state?.configuration.homepage.joinUs;
  }

  @Selector([CollectivityState])
  static footerVariableSentence(state: CollectivityStateModel): string | undefined {
    return state?.configuration.footer.sentence;
  }

  @Selector([CollectivityState])
  static availableLegals(state: CollectivityStateModel): (AvailableLegal | undefined)[] {
    return (
      state?.configuration.footer.availableLegalTypes
        ?.map((legalPageType) => availableLegalsMap.get(legalPageType))
        ?.filter((legal) => !!legal) ?? []
    );
  }

  @Selector([CollectivityState])
  static footerLinks(state: CollectivityStateModel): LinkInterface[] {
    return state?.configuration.footer.links ?? [];
  }

  @Selector([CollectivityState])
  static discoverCity(state: CollectivityStateModel): HomepageDiscoverCityInterface | undefined {
    return state?.configuration.homepage.discoverCity;
  }

  @Selector([CollectivityState])
  static drive(state: CollectivityStateModel): HomepageDriveInterface | undefined {
    return state?.configuration.homepage.drive;
  }

  @Selector([CollectivityState])
  static money(state: CollectivityStateModel): HomepageMoneyInterface | undefined {
    return state?.configuration.homepage.money;
  }

  @Selector([CollectivityState])
  static cityBus(state: CollectivityStateModel): HomepageCityBusInterface | undefined {
    return state?.configuration.homepage.cityBus;
  }

  @Selector([CollectivityState])
  static contactPage(state: CollectivityStateModel): ContactPageInterface | undefined {
    return state?.configuration.contactPage;
  }

  @Selector([CollectivityState])
  static seo(state: CollectivityStateModel): SEOInterface | undefined {
    return state?.configuration.seo;
  }

  @Selector([CollectivityState])
  static cityName(state: CollectivityStateModel): string | undefined {
    return state?.configuration.seo.cityName;
  }

  @Selector([CollectivityState])
  static crmData(state: CollectivityStateModel): CRMDataInterface | undefined {
    return state?.configuration.crmData;
  }

  @Selector([CollectivityState])
  static cmsBlogId(state: CollectivityStateModel): string | undefined {
    return state?.configuration.cmsBlogId;
  }

  @Selector([CollectivityState.collectivity])
  static productId(collectivity: CollectivityStateModel): string | undefined {
    return collectivity?.productName
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '');
  }

  @Selector([CollectivityState.seo])
  static defaultSEOTitle(seo: SEOInterface | undefined): string | undefined {
    return seo?.metaTitle ?? seo?.cityName ? getTitle(seo.cityName) : undefined;
  }

  @Selector([CollectivityState])
  static defaultSEODescription(state: CollectivityStateModel): string | undefined {
    return state?.configuration.seo?.metaDescription ?? state?.configuration.crmData?.citizen?.inclusive
      ? getDescription(state?.configuration.crmData.citizen.inclusive)
      : undefined;
  }

  @Selector([CollectivityState.seo])
  static defaultSEOOgImageRec(seo: SEOInterface | undefined): ImageInterface | undefined {
    return seo?.ogImageRec;
  }

  @Selector([CollectivityState.seo])
  static defaultSEOOgImageSquare(seo: SEOInterface | undefined): ImageInterface | undefined {
    return seo?.ogImageSquare;
  }

  ngxsOnInit(context: StateContext<CollectivityStateModel>): void {
    context.setState((window as any).collectivity);
  }
}
