import { CommonModule } from '@angular/common';
import { Directive, EventEmitter, HostListener, NgModule, Output } from '@angular/core';

@Directive({
  selector: '[appPressEscapeKey]',
})
export class PressEscapeKeyDirective {
  @Output()
  appPressEscapeKey = new EventEmitter<KeyboardEvent>();

  @HostListener('window:keydown.escape', ['$event'])
  onKeyPressed(event: KeyboardEvent): void {
    this.appPressEscapeKey.emit(event);
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [PressEscapeKeyDirective],
  declarations: [PressEscapeKeyDirective],
})
export class PressEscapeKeyDirectiveModule {}
