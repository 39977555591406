import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '@core/services/login/login.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { SignInRoutingEnum } from '@features/sign-in/sign-in-routing.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { SetTokens } from '@stores/jwt/jwt.actions';
import { AuthenticationWebService } from '@webservices/authentication/authentication.web-service';
import { DataStorageService } from '@wizbii/angular-utilities';
import { BehaviorSubject, catchError, debounceTime, EMPTY, filter, Observable, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  static readonly TOKEN_KEY = 'login-token';
  FeaturesRoutingEnum = FeaturesRoutingEnum;
  SignInRoutingEnum = SignInRoutingEnum;
  form!: FormGroup;
  error$ = new BehaviorSubject<boolean>(false);

  @Select(CollectivityState.id)
  collectivityId$!: Observable<string>;

  get email(): AbstractControl | null {
    return this.form.get('email');
  }
  collectivityDomain$!: Observable<string>;

  constructor(
    private readonly store: Store,
    private readonly loginService: LoginService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthenticationWebService,
    private readonly dataStorage: DataStorageService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl(this.dataStorage.get('email') ?? '', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });

    this.email?.valueChanges.pipe(untilDestroyed(this), debounceTime(300)).subscribe((email) => {
      this.dataStorage.put('email', email);
    });

    this.collectivityDomain$ = this.store
      .select(CollectivityState.formattedDomain)
      .pipe(filter((domain): domain is string => !!domain));
  }

  handleSubmit(formGroup: FormGroup, collectivityId: string): void {
    this.error$.next(false);
    this.authService
      .signIn(formGroup.value.email, formGroup.value.password, collectivityId)
      .pipe(
        switchMap((tokens) => this.store.dispatch(new SetTokens(tokens, false))),
        catchError(() => {
          this.error$.next(true);
          return EMPTY;
        })
      )
      .subscribe({
        next: () => this.loginService.redirectByServiceId(this.activatedRoute.snapshot.queryParams['service-id']),
      });
  }
}
