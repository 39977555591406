import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { JwtState } from '@stores/jwt/jwt.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NotLoggedGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly store: Store) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.selectOnce(JwtState.tokens).pipe(
      map((tokens) => {
        const notLogged = !tokens;

        const urlTree = this.router.parseUrl(state.url);
        urlTree.root = this.router.parseUrl('/').root;
        return notLogged || urlTree;
      })
    );
  }
}
