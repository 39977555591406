<div class="card" *ngIf="(dates$ | async) !== undefined">
  <div class="card__wrap">
    <app-events-calendar-header
      [currentDate]="calendar.activeDate"
      (nextMonth)="changeMonth(1)"
      (previousMonth)="changeMonth(-1)"
    ></app-events-calendar-header>

    <mat-calendar
      #calendar
      class="events-calendar"
      [dateClass]="(dates$ | async)! | dateClass"
      [selected]="(selectedDate$ | async)!"
      (selectedChange)="changeDate($event)"
    ></mat-calendar>
  </div>

  <div *ngIf="(currentEvents$ | async)?.length" class="card__events" aria-label="Événements">
    <app-event-calendar-card
      *ngFor="let event of currentEvents$ | async; trackBy: trackByEvent"
      class="card__events__item"
      [event]="event"
      (click)="goToEvent(event.id, event.origin)"
    ></app-event-calendar-card>
  </div>
</div>
