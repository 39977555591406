import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowResizeService {
  private readonly _ratio$: BehaviorSubject<number> = new BehaviorSubject(1);

  readonly ratio$: Observable<number> = this._ratio$.asObservable();

  setWindowSize(): void {
    this._ratio$.next(window.innerHeight / 100);
  }
}
