import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { IncludePipeModule } from '@commons/pipes/include/include.pipe';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { UserRoutingEnum } from '@features/user/user-routing.enum';
import { Store } from '@ngxs/store';
import { Logout } from '@stores/jwt/jwt.actions';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDropdownComponent {
  @Input() userName?: string;
  @Input() roles: string[] | null | undefined;
  @Input() extranetUrl: string | null | undefined;

  @Output() closeMenu = new EventEmitter<string>();

  @ViewChild(MatMenuTrigger, { read: ElementRef })
  userMenu!: ElementRef<HTMLElement>;

  EXTRANET_ROLES = ['ROLE_EXTRANET_COLLECTIVITE_CONTRIBUTOR', 'ROLE_EXTRANET_COLLECTIVITE_ADMIN'];

  constructor(private readonly store: Store) {}

  FeaturesRoutingEnum = FeaturesRoutingEnum;
  UserRoutingEnum = UserRoutingEnum;

  get userMenuWidth(): number {
    return this.userMenu?.nativeElement?.clientWidth;
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule.forChild([]), MatButtonModule, MatMenuModule, IncludePipeModule],
  declarations: [UserDropdownComponent],
  exports: [UserDropdownComponent],
})
export class UserDropdownModule {}
