import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ServiceInterface } from '@b2b/models';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Select } from '@ngxs/store';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { map, Observable } from 'rxjs';

@Injectable()
export class AvailableServiceGuard implements CanActivate {
  @Select(CollectivityState.services)
  services$!: Observable<ServiceInterface[]>;

  constructor(private readonly router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.services$.pipe(
      map((services) =>
        services.some((service) => state.url.includes(service.menuLink))
          ? true
          : this.router.parseUrl(FeaturesRoutingEnum.NotFound)
      )
    );
  }
}
