<footer role="contentinfo" class="footer">
  <div class="footer__firstLine">
    <section role="region" class="footer__left">
      <img class="footer__left__logo" src="{{ logoWhite$ | async }}" alt="Logo site" loading="lazy" />
      <p *ngIf="footerVariableSentence$ | async as footerVariableSentence" class="footer__left__sentence">
        {{ footerVariableSentence }}
      </p>
      <ul class="ul-clean social-medias">
        <li *ngFor="let social of socials$ | async; trackBy: trackBySocialLabel">
          <a
            class="social-medias__link"
            href="{{ social.url }}"
            target="_blank"
            [attr.aria-label]="'Lien ' + social.label"
            (click)="trackEvent(eventCategory, 'Click ' + social.label)"
          >
            <mat-icon class="social-medias__link__logo" [svgIcon]="social.label" role="presentation"></mat-icon>
          </a>
        </li>
      </ul>
    </section>

    <section role="region" class="footer-section">
      <h2 role="heading" class="footer-section__header">
        <ng-container *ngIf="isWebNotIpadPro$ | async">À Propos</ng-container>
        <button
          *ngIf="(isWebNotIpadPro$ | async) === false"
          class="expand-button"
          type="button"
          aria-label="Afficher la section À Propos"
          [attr.aria-expanded]="!isCollapsed.about"
          (click)="isCollapsed.about = !isCollapsed.about; trackEvent(eventCategory, 'Click À Propos')"
        >
          À Propos
          <mat-icon svgIcon="chevron_down" role="presentation"></mat-icon>
        </button>
      </h2>

      <ul class="ul-clean footer-section__links" [attr.expanded]="!isCollapsed.about || (isWebNotIpadPro$ | async)">
        <li *ngFor="let availableLegal of availableLegals$ | async; trackBy: trackByAvailableLegalLink">
          <a
            routerLink="{{ availableLegal.link }}"
            (click)="trackEvent(eventCategory, 'Click ' + availableLegal.label)"
          >
            {{ availableLegal.label }}
          </a>
        </li>
        <li>
          <button type="button" (click)="openCookiesModal()" aria-label="Ouvrir la modale de gestion des cookies">
            Gestion des cookies
          </button>
        </li>
      </ul>
    </section>

    <section role="region" class="footer-section">
      <h2 role="heading" class="footer-section__header">
        <ng-container *ngIf="isWebNotIpadPro$ | async">Plan du site</ng-container>
        <button
          *ngIf="(isWebNotIpadPro$ | async) === false"
          class="expand-button"
          type="button"
          aria-label="Afficher la section Plan du site"
          [attr.aria-expanded]="!isCollapsed.plan"
          (click)="isCollapsed.plan = !isCollapsed.plan; trackEvent(eventCategory, 'Click Plan du site')"
        >
          Plan du site
          <mat-icon svgIcon="chevron_down" role="presentation"></mat-icon>
        </button>
      </h2>

      <ul class="ul-clean footer-section__links" [attr.expanded]="!isCollapsed.plan || (isWebNotIpadPro$ | async)">
        <li>
          <a [routerLink]="''" (click)="trackEvent(eventCategory, 'Click Home')"> Accueil</a>
        </li>
        <li *ngFor="let service of services$ | async; trackBy: trackByMenuLabel">
          <a
            *ngIf="!service.redirect"
            [routerLink]="service.link"
            (click)="trackEvent(eventCategory, 'Click ' + service.menuLabel)"
          >
            {{ service.menuLabel }}
          </a>
          <a
            *ngIf="!!service.redirect"
            [href]="service.link"
            target="_blank"
            (click)="trackEvent(eventCategory, 'Click ' + service.menuLabel)"
          >
            {{ service.menuLabel }}
          </a>
        </li>
        <li>
          <a [routerLink]="['/', FeaturesRoutingEnum.Contact]" (click)="trackEvent(eventCategory, 'Click Contact')">
            Contact</a
          >
        </li>
      </ul>
    </section>

    <section *ngIf="footerLinks$ | async as footerLinks" role="region" class="footer-section">
      <ng-container *ngIf="footerLinks.length > 0">
        <h2 role="heading" class="footer-section__header">
          <ng-container *ngIf="isWebNotIpadPro$ | async">Liens rapides</ng-container>
          <button
            *ngIf="(isWebNotIpadPro$ | async) === false"
            class="expand-button"
            type="button"
            aria-label="Afficher la section liens rapides"
            [attr.aria-expanded]="!isCollapsed.links"
            (click)="isCollapsed.links = !isCollapsed.links; trackEvent(eventCategory, 'Click Liens rapides')"
          >
            Liens rapides
            <mat-icon svgIcon="chevron_down" role="presentation"></mat-icon>
          </button>
        </h2>

        <ul class="ul-clean footer-section__links" [attr.expanded]="!isCollapsed.links || (isWebNotIpadPro$ | async)">
          <li *ngFor="let footerLink of footerLinks; trackBy: trackByFooterLinkLabel">
            <a
              href="{{ footerLink.url }}"
              target="_blank"
              (click)="trackEvent(eventCategory, 'Click ' + footerLink.label)"
              >{{ footerLink.label }}</a
            >
          </li>
        </ul>
      </ng-container>
    </section>
  </div>

  <div class="footer__secondLine">
    <div class="footer__secondLine__left">
      <img
        *ngIf="bottomLeftLogo$ | async as bottomLeftLogo"
        [src]="bottomLeftLogo.url"
        [style]="bottomLeftLogo | imageSizeStyle"
        alt="Bottom left logo footer"
        loading="lazy"
        class="bottomLeftLogo"
      />
    </div>

    <div class="footer__secondLine__right">
      <p class="copyright">{{ copyright$ | async }}</p>
      <img
        *ngIf="bottomRightLogo$ | async as bottomRightLogo"
        [src]="bottomRightLogo.url"
        [style]="bottomRightLogo | imageSizeStyle"
        class="bottomRightLogo"
        loading="lazy"
        alt="Bottom right logo footer"
      />
    </div>
  </div>
</footer>
