import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Select, Store } from '@ngxs/store';
import { Logout, RefreshTokensStore, SetTokens } from '@stores/jwt/jwt.actions';
import { JwtState } from '@stores/jwt/jwt.state';
import { JwtServiceInterface, JwtTokens } from '@wizbii/jwt';
import { map, Observable, switchMap } from 'rxjs';
import { AuthenticationWebService } from '@webservices/authentication/authentication.web-service';
import { CollectivityState } from '@stores/collectivity/collectivity.state';

@Injectable()
export class JwtService implements JwtServiceInterface {
  blacklistRoutes: (string | RegExp)[];

  @Select(CollectivityState.id)
  collectivityId$!: Observable<string>;

  constructor(private readonly store: Store, private readonly authService: AuthenticationWebService) {
    this.blacklistRoutes = [
      'https://storage.googleapis.com',
      new RegExp(`https://auth.${environment.apiDomain}(?!(/v1/user).*(/email|password|overview))`, 'i'),
    ];
  }

  getTokens(): Observable<JwtTokens | undefined> {
    return this.store.selectOnce(JwtState.tokens);
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.collectivityId$.pipe(
      switchMap((collectivityId) => this.authService.fromRefreshToken(tokens, collectivityId)),
      switchMap((newTokens) => this.store.dispatch(new RefreshTokensStore(newTokens)).pipe(map(() => newTokens)))
    );
  }

  saveLocalTokens(jwtTokens: JwtTokens): void {
    this.store.dispatch(new SetTokens(jwtTokens, true));
  }
}
