<div class="header">
  <button matRipple class="btn-clean header__btn" (click)="previousMonth.emit()">
    <mat-icon svgIcon="arrow_left" role="presentation"></mat-icon>
  </button>
  <div class="header__label">
    <span class="header__label__month"> {{ currentDate | date : 'MMMM' }}</span>
    <span> {{ currentDate | date : 'YYYY' }}</span>
  </div>
  <button matRipple class="btn-clean header__btn" (click)="nextMonth.emit()">
    <mat-icon svgIcon="arrow_right" role="presentation"></mat-icon>
  </button>
</div>

<div class="days">
  <span>Dim</span>
  <span>Lun</span>
  <span>Mar</span>
  <span>Mer</span>
  <span>Jeu</span>
  <span>Ven</span>
  <span>Sam</span>
</div>
