import { Contact } from '@models/event/contact';
import { EventForm, EventFormExternal } from '@models/event/event-form';
import { EventStatusEnum } from '@models/event/event-status.enum';
import { OrganizationLight } from '@models/organization/organization';
import { convertDate } from '@models/utils';
import { City } from '@wizbii/models';

export interface Event {
  id: string;
  name: string;
  description?: string;
  status: EventStatusEnum;
  organizationName?: string;
  organizationId?: string;
  organization?: OrganizationLight;
  contact?: Contact;
  website?: string;
  redirectUrl?: string;
  type: string;
  metaTitle: string;
  metaDescription: string;
  startDate: Date;
  endDate: Date;
  location?: City;
  areas?: City[];
  thumbnail?: string;
  form?: EventForm;
  isFormEnabled: boolean;
  formExternal?: EventFormExternal;
  isFormExternalEnabled: boolean;
  seo: boolean;
  online: boolean;
  salonUrl?: string;
  source?: string;
  registrationProcedure?: string;
  receptionEmail?: string;
}

export interface AlgoliaEventResponse {
  name: string;
  objectID: string;
  id?: string;
  location?: City;
  description?: string;
  redirectUrl: string;
  startDate: Date | string;
  endDate: Date | string;
  status: EventStatusEnum;
  thumbnail?: string;
  type: string;
  source: string;
  website: string;
  areas?: City[];
  organizationName: string;
  organizationId: string;
  online: boolean;
  salonUrl: string;
  _rankingInfo: { geoDistance: number };
}

export interface EventDate {
  day: number;
  month: number;
  year: number;
}

export interface EventTime {
  hour: number;
  minutes: number;
}

export function toEvent(data: any = {}): Event {
  return {
    ...data,
    id: data.id ?? data.objectID,
    startDate: convertDate(data.startDate),
    endDate: convertDate(data.endDate),
    location: data.location ? new City(data.location) : undefined,
    areas: data.areas && data.areas.length ? data.areas.map((area: any) => new City(area)) : undefined,
    form: data.formInternalResource,
    formExternal: data.formExternalResource,
    isFormExternalEnabled: !!data.formExternalResource,
  };
}
