import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ClickOutsideDirectiveModule } from '@commons/directives/click-outside/click-outside.directive';
import { EventsCalendarModule } from '@domains/events/events-agenda/events-calendar/events-calendar.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-events-agenda',
  templateUrl: './events-agenda.component.html',
  styleUrls: ['./events-agenda.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsAgendaComponent {
  @Output() closeMenu = new EventEmitter<string>();
  @Input() isInHeader!: boolean;

  showCalendar$ = new BehaviorSubject<boolean>(false);
  preventPrematureClosure = false;
  notifyEvents = true;
  constructor() {}

  toggleCalendar(): void {
    this.notifyEvents = false;
    this.showCalendar$.next(!this.showCalendar$.value);
    this.preventPrematureClosure = true;
  }

  closeCalendar(event: any, clickOutside: boolean): void {
    if (event && !this.preventPrematureClosure) {
      this.showCalendar$.next(false);
    }
    if (!clickOutside) this.closeMenu.emit();
    this.preventPrematureClosure = false;
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, EventsCalendarModule, ClickOutsideDirectiveModule],
  declarations: [EventsAgendaComponent],
  exports: [EventsAgendaComponent],
})
export class EventsAgendaModule {}
