import { JwtTokens } from '@wizbii/jwt';

export class RefreshTokensStore {
  static readonly type = '[Jwt] Refresh Tokens Store';
  constructor(public tokens: JwtTokens) {}
}

export class Logout {
  static readonly type = '[Jwt] Logout';
}

export class SetTokens {
  static readonly type = '[Jwt] Set Tokens';
  constructor(public tokens: JwtTokens, public isPersistent: boolean) {}
}
