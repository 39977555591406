import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { MenuItem, SubItem } from '@models/menu/menu-item';

@Component({
  selector: 'app-sub-menu-panel[displaySubItem]',
  templateUrl: './sub-menu-panel.component.html',
  styleUrls: ['./sub-menu-panel.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: '{{transform}}', width: '{{width}}', willChange: 'transform' }),
        animate('{{duration}}ms cubic-bezier(.645, .045, .355, 1)'),
      ]),
      transition(':leave', [
        style({ willChange: 'transform' }),
        animate('{{duration}}ms cubic-bezier(.645, .045, .355, 1)', style({ transform: '{{transform}}' })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubMenuPanelComponent {
  @Input() isWebNotIpadPro?: boolean | null;
  @Input() displaySubItem?: MenuItem | null;
  @Input() isOpen = false;

  @Output() navigate = new EventEmitter<string>();

  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;

  trackBySubItem(_: number, subItem: SubItem): string {
    return subItem.label;
  }
}

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [SubMenuPanelComponent],
  exports: [SubMenuPanelComponent],
})
export class SubMenuPanelModule {}
