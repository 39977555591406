import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private readonly router: Router) {}

  redirectByServiceId(serviceId?: FeaturesRoutingEnum): void {
    switch (serviceId) {
      case FeaturesRoutingEnum.Drive:
        this.router.navigate(['/', FeaturesRoutingEnum.Drive, FeaturesRoutingEnum.Lessons]);
        break;
      case FeaturesRoutingEnum.Money:
        this.router.navigate(['/', FeaturesRoutingEnum.Money, FeaturesRoutingEnum.Simulation]);
        break;
      default:
        this.router.navigate(['/']);
    }
  }
}
