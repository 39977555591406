import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { MenuItem } from '@models/menu/menu-item';

export const homeMenuItem: MenuItem = {
  label: 'Accueil',
  url: '',
  matchExactUrl: true,
  eventAction: 'Click Home',
};

export const contactMenuItem: MenuItem = {
  label: 'Contact',
  url: FeaturesRoutingEnum.Contact,
  eventAction: 'Click Contact',
};
