import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EmailPreferenceInterface } from '@models/subscription/subscription';
import { ProfileStateModel } from '@stores/profile/profile.state';
import { UserStateModel } from '@stores/user/user.state';
import { JwtTokens } from '@wizbii/jwt';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountWebService {
  constructor(private readonly http: HttpClient) {}

  createAccount(profile: any, platformId: string): Observable<JwtTokens> {
    return this.http.post<JwtTokens>(`${environment.api.account}/v2/account`, {
      ...profile,
      appId: environment.applicationId,
      platform: `b2b-collectivity-${platformId}`,
    });
  }

  deleteAccount(profileId: string): Observable<JwtTokens> {
    return this.http.delete<JwtTokens>(`${environment.api.account}/v1/account/${profileId}`, {
      body: {
        DeleteReasons: '',
      },
    });
  }

  downloadPersonalData(platformId: string): Observable<void> {
    return this.http.post<void>(`${environment.api.account}/v1/personal-data/request-export`, {
      locale: environment.i18n.locale,
      service: `b2b-collectivity-${platformId}`,
    });
  }

  activateService(user: UserStateModel, profile: ProfileStateModel, appId: string): Observable<void> {
    return this.http.post<void>(`${environment.api.account}/v1/activate/${appId}`, {
      ...user,
      ...profile,
      dateBirthday: profile?.birthDate,
    });
  }

  getEmailPreference(id: string): Observable<EmailPreferenceInterface> {
    return this.http.get<EmailPreferenceInterface>(`${environment.api.account}/v2/email-preferences/${id}`);
  }

  updateEmailPreference(folders: Record<string, boolean>): Observable<void> {
    return this.http.post<void>(
      `${environment.api.collectivityPrefix}.${environment.apiDomain}/v1/user/email-preferences`,
      {
        folders,
        campaigns: [],
        appId: environment.applicationId,
      }
    );
  }
}
