import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Social } from '@b2b/models';
import { SubMenuPanelModule } from '@commons/burger-menu/sub-menu-panel/sub-menu-panel.component';
import { PressEscapeKeyDirectiveModule } from '@commons/directives/press-escape-key/press-escape-key.directive';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { EventsAgendaModule } from '@domains/events/events-agenda/events-agenda.component';
import { UserDropdownModule } from '@domains/user-dropdown/user-dropdown.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { MenuItem } from '@models/menu/menu-item';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { JwtState } from '@stores/jwt/jwt.state';
import { ProfileState, ProfileStateModel } from '@stores/profile/profile.state';
import { trackEvent } from '@wizbii/tracking';
import { enableBodyScroll } from 'body-scroll-lock';
import { BehaviorSubject, Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-burger-menu-main[mainContainerClass][menuItemId][openMenuButtonId]',
  templateUrl: './burger-menu-main.component.html',
  styleUrls: ['./burger-menu-main.component.scss', './burger-menu-socials.scss', './burger-menu-nav-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BurgerMenuMainComponent implements OnDestroy {
  @Input() isOpen = false;
  @Input() mainContainerClass = 'main-container';
  @Input() menuItemId = 'menu-item';
  @Input() openMenuButtonId = 'burger-menu-open-button';
  @Input() currentNestedNavigation?: MenuItem;

  @Output() closeMenu = new EventEmitter<void>();

  @Select(CollectivityState.extranetUrl)
  extranetUrl$!: Observable<string>;

  @Select(CollectivityState.logoSecondary)
  logoSrc$!: Observable<string | undefined>;

  @Select(CollectivityState.socials)
  socialNetworks$!: Observable<Social[] | undefined>;

  @Select(CollectivityState.menuHero1)
  menuHero1Src$!: Observable<string>;

  @Select(CollectivityState.menuHero2)
  menuHero2Src$!: Observable<string>;

  @Select(CollectivityState.menuHero3)
  menuHero3Src$!: Observable<string>;

  @Select(ProfileState)
  profile$!: Observable<ProfileStateModel>;

  @Select(JwtState.roles)
  roles$!: Observable<string[]>;

  readonly displaySubItem$ = new BehaviorSubject<MenuItem | undefined>(undefined);

  @Input() eventCategory!: string;
  @Input() menuItems!: MenuItem[];

  readonly focusSubMenuPanelId = 'sub-menu-panel';
  readonly trackEvent = trackEvent;
  FeaturesRoutingEnum = FeaturesRoutingEnum;

  get isWebNotIpadPro$(): Observable<boolean> {
    return this.breakpointsService.isWebNotIpadPro$;
  }

  constructor(private readonly breakpointsService: BreakpointsService, private readonly element: ElementRef) {}

  displaySubItem(menuItem: MenuItem): void {
    if (this.displaySubItem$.value === menuItem) {
      this.displaySubItem$.next(undefined);
    } else {
      this.displaySubItem$.next(menuItem);
      window.setTimeout(() => {
        const elmToFocus = this.element.nativeElement.querySelector(`#${this.focusSubMenuPanelId}-0`);
        if (elmToFocus) {
          elmToFocus.focus();
        }
      });
    }

    this.trackEvent(this.eventCategory, menuItem.eventAction);
  }

  closePanel(eventAction: string): void {
    this.closeMenu.emit();
    this.displaySubItem$.next(undefined);

    window.setTimeout(() => {
      (document.querySelector(`#${this.openMenuButtonId}`) as HTMLElement)?.focus();
    });

    enableBodyScroll(document.querySelector(`.${this.mainContainerClass}`) || ('' as any));
    this.trackEvent(this.eventCategory, eventAction);
  }

  trackByItem(_: number, item: MenuItem): string {
    return item.label;
  }

  trackBySocial(_: number, social: Social): string {
    return social.label;
  }

  ngOnDestroy(): void {
    this.displaySubItem$.complete();
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    SubMenuPanelModule,
    RouterModule,
    A11yModule,
    PressEscapeKeyDirectiveModule,
    UserDropdownModule,
    EventsAgendaModule,
  ],
  declarations: [BurgerMenuMainComponent],
  exports: [BurgerMenuMainComponent],
})
export class BurgerMenuMainModule {}
