<div class="illustrations">
  <mat-icon class="illustrations__1" svgIcon="404-1" [inline]="true"></mat-icon>
  <mat-icon class="illustrations__2" svgIcon="404-2" [inline]="true"></mat-icon>
</div>

<div id="mainContent" class="text">
  <h1 class="text__title">
    Erreur 404&nbsp;!<br />
    Page introuvable
  </h1>
  <p class="text__text">Oooopppps...<br />Nous sommes désolés mais la page que vous recherchez n&apos;existe pas.</p>
  <a class="btn" [routerLink]="['/']">Retour à la page d&apos;accueil</a>
</div>
