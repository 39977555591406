import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ImageInterface, LinkInterface, ServiceInterface, Social } from '@b2b/models';
import { ImageSizeStyleModule } from '@commons/pipes/image-size/image-size-style.pipe';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { AvailableLegal } from '@models/legal/legal-page-map';
import { Select } from '@ngxs/store';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { trackEvent } from '@wizbii/tracking';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', './footer-section.component.scss', './footer-expand-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  get isWebNotIpadPro$(): Observable<boolean> {
    return this.breakpointsService.isWebNotIpadPro$;
  }

  @Select(CollectivityState.services)
  services$!: Observable<ServiceInterface[]>;

  @Select(CollectivityState.logoWhite)
  logoWhite$!: Observable<string | undefined>;

  @Select(CollectivityState.socials)
  socials$!: Observable<Social[] | undefined>;

  @Select(CollectivityState.footerVariableSentence)
  footerVariableSentence$!: Observable<string | undefined>;

  @Select(CollectivityState.availableLegals)
  availableLegals$!: Observable<AvailableLegal[]>;

  @Select(CollectivityState.footerLinks)
  footerLinks$!: Observable<LinkInterface[]>;

  @Select(CollectivityState.bottomLeftLogo)
  bottomLeftLogo$!: Observable<ImageInterface | undefined>;

  @Select(CollectivityState.bottomRightLogo)
  bottomRightLogo$!: Observable<ImageInterface | undefined>;

  @Select(CollectivityState.copyright)
  copyright$!: Observable<string | undefined>;

  readonly trackEvent = trackEvent;
  readonly eventCategory = 'Footer';
  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;

  isCollapsed = {
    about: true,
    plan: true,
    links: true,
  };

  constructor(private readonly breakpointsService: BreakpointsService) {}

  openCookiesModal(): void {
    document.dispatchEvent(new CustomEvent<void>('WizbiiGdpr.toggleDialogVisibility'));
    this.trackEvent(this.eventCategory, 'Click Gestion des cookies');
  }

  trackBySocialLabel(_: number, social: Social): string {
    return social.label;
  }

  trackByAvailableLegalLink(_: number, availableLegal: AvailableLegal): FeaturesRoutingEnum {
    return availableLegal.link;
  }

  trackByMenuLabel(_: number, service: ServiceInterface): string {
    return service.menuLabel;
  }

  trackByFooterLinkLabel(_: number, footerLink: LinkInterface): string {
    return footerLink.label;
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, ImageSizeStyleModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
