import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Logout, RefreshTokensStore, SetTokens } from '@stores/jwt/jwt.actions';
import { deserializeJwt, JwtTokens } from '@wizbii/jwt';
import { CookieService } from 'ngx-cookie-service';

export interface JwtStateInterface {
  tokens?: JwtTokens;
  uniqUserId?: string;
}

const JwtStateToken = new StateToken<JwtStateInterface>('jwt');

@State<JwtStateInterface>({
  name: JwtStateToken,
  defaults: {},
})
@Injectable()
export class JwtState implements NgxsOnInit {
  static readonly TOKEN_KEY = (id: string): string => `${id}_tokens`;
  static readonly EXPIRY_KEY = (id: string): string => `${id}_tokens_expiry`;
  static readonly UNIQ_USER_ID_KEY = `uniqUserId`;

  @Selector([JwtState])
  static isConnected(state: JwtStateInterface): boolean {
    return !!state.tokens;
  }

  @Selector([JwtState])
  static tokens(state: JwtStateInterface): JwtTokens | undefined {
    return state.tokens;
  }

  @Selector([JwtState])
  static uniqUserId(state: JwtStateInterface): string | undefined {
    return state.uniqUserId;
  }

  @Selector([JwtState])
  static userId(state: JwtStateInterface): string | undefined {
    return state?.tokens?.token ? deserializeJwt(state.tokens.token)?.['user-id'] : undefined;
  }

  @Selector([JwtState])
  static roles(state: JwtStateInterface): string[] | undefined {
    return state?.tokens?.token ? deserializeJwt(state.tokens.token)?.['roles'] : [];
  }

  constructor(private readonly cookieService: CookieService) {}

  ngxsOnInit(ctx?: StateContext<JwtStateInterface>): void {
    const collectivity = (window as any).collectivity;
    const id = this.cookieService.get(JwtState.UNIQ_USER_ID_KEY);

    ctx?.patchState({ tokens: this.readTokens(collectivity.id), uniqUserId: id });
  }

  @Action(RefreshTokensStore)
  refreshTokensStore(ctx: StateContext<JwtStateInterface>, { tokens }: RefreshTokensStore): void {
    ctx.patchState({ tokens });
  }

  @Action(SetTokens)
  setTokens(ctx: StateContext<JwtStateInterface>, { tokens, isPersistent }: SetTokens): void {
    const collectivity = (window as any).collectivity;
    this.writeTokens(tokens, collectivity.id, isPersistent);

    ctx.patchState({
      tokens,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<JwtStateInterface>): void {
    const collectivity = (window as any).collectivity;

    this.deleteTokens(collectivity.id);
    ctx.setState({});
    window.open('/', '_self');
  }

  private readTokens(collectivityId: string): JwtTokens | undefined {
    const rawTokens = JSON.parse(this.cookieService.get(JwtState.TOKEN_KEY(collectivityId)) || 'null');
    return rawTokens ?? undefined;
  }

  private writeTokens(tokens: JwtTokens, collectivityId: string, persistent = true) {
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(JwtState.EXPIRY_KEY(collectivityId));
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(JwtState.EXPIRY_KEY(collectivityId)))
      : new Date(Date.now() + msIn390Days);

    if (!expiryExists) {
      this.cookieService.set(
        JwtState.EXPIRY_KEY(collectivityId),
        persistent ? expiry.getTime().toString() : 'Session',
        persistent ? expiry : undefined,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );
    }

    this.cookieService.set(
      JwtState.TOKEN_KEY(collectivityId),
      JSON.stringify(tokens),
      persistent ? expiry : undefined,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
  }

  private deleteTokens(collectivityId: string) {
    this.cookieService.delete(JwtState.TOKEN_KEY(collectivityId), '/');
    this.cookieService.delete(JwtState.TOKEN_KEY(collectivityId), '/', this.getCookieDomain());
  }
}
