import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ImageInterface } from '@b2b/models';

@Pipe({ name: 'imageSizeStyle' })
export class ImageSizeStylePipe implements PipeTransform {
  transform(image: ImageInterface): { height?: string; width?: string } {
    return {
      ...(image.height ? { height: `${image.height}px` } : {}),
      ...(image.width ? { width: `${image.width}px` } : {}),
    };
  }
}

@NgModule({
  declarations: [ImageSizeStylePipe],
  exports: [ImageSizeStylePipe],
  providers: [ImageSizeStylePipe],
  imports: [CommonModule],
})
export class ImageSizeStyleModule {}
