<div *ngIf="isMobile; else blockContent" mat-dialog-content>
  <ng-container *ngTemplateOutlet="blockContent"></ng-container>
</div>

<ng-template #blockContent>
  <section role="region" *ngIf="!saving && !saved; else formSubmitted">
    <header class="header">
      <h3 role="heading" class="header__title">Contactez-nous</h3>
    </header>

    <form class="form" [formGroup]="form" (ngSubmit)="form.valid && doSubmit(form.value)">
      <!-- lecture du prénom -->
      <mat-form-field class="mat-form-field--firstName">
        <input type="text" matInput formControlName="firstName" aria-label="Prénom" placeholder="Prénom" />
        <mat-error *ngIf="form.controls.firstName.errors as errors">
          <ng-container *ngIf="errors.required">Ce champ est requis</ng-container>
        </mat-error>
      </mat-form-field>

      <!-- lecture du nom -->
      <mat-form-field class="mat-form-field--lastName">
        <input type="text" matInput formControlName="lastName" aria-label="Nom" placeholder="Nom" />
        <mat-error *ngIf="form.controls.lastName.errors as errors">
          <ng-container *ngIf="errors.required">Ce champ est requis</ng-container>
        </mat-error>
      </mat-form-field>

      <!-- lecture de l'adresse email -->
      <mat-form-field class="mat-form-field--email">
        <input type="email" matInput formControlName="email" aria-label="Adresse email" placeholder="Adresse email" />
        <mat-error *ngIf="form.controls.email.errors as errors">
          <ng-container *ngIf="errors.required">Ce champ est requis</ng-container>
          <ng-container *ngIf="errors.email">Ce format d&apos;adresse email est invalide</ng-container>
        </mat-error>
      </mat-form-field>

      <!-- lecture du numéro de téléphone -->
      <mat-form-field class="mat-form-field--phone">
        <input type="phone" matInput formControlName="phone" aria-label="Téléphone" placeholder="Téléphone" />
        <mat-error *ngIf="form.controls.phone.errors as errors">
          <ng-container *ngIf="errors.required">Ce champ est requis</ng-container>
        </mat-error>
      </mat-form-field>

      <!-- lecture du sujet -->
      <mat-form-field class="mat-form-field--subject">
        <input type="text" matInput formControlName="subject" aria-label="Sujet" placeholder="Sujet" />
        <mat-error *ngIf="form.controls.subject.errors as errors">
          <ng-container *ngIf="errors.required">Ce champ est requis</ng-container>
        </mat-error>
      </mat-form-field>

      <!-- lecture du message -->
      <mat-form-field class="mat-form-field--content mat-form-field--textarea">
        <mat-label>Message</mat-label>
        <textarea matInput formControlName="content" aria-label="Message" placeholder="Message"></textarea>
        <mat-error *ngIf="form.controls.content.errors as errors">
          <ng-container *ngIf="errors.required">Ce champ est requis</ng-container>
        </mat-error>
      </mat-form-field>

      <div class="commands">
        <button type="submit" class="btn btn-submit">Envoyer</button>
      </div>
    </form>
  </section>

  <button
    *ngIf="!saving"
    mat-icon-button
    type="button"
    aria-label="Fermer le formulaire"
    class="btn-clean btn-close"
    (click)="closeDialog()"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</ng-template>

<ng-template #formSubmitted>
  <app-checkmark-loader class="loader" [loading]="saving">Envoi…</app-checkmark-loader>

  <h4 role="heading" class="title">
    <ng-container *ngIf="saving">Envoi en cours</ng-container>
    <ng-container *ngIf="saved">Votre message a bien été envoyé&nbsp;!</ng-container>
  </h4>

  <ng-container *ngIf="saved">
    <p class="more-info">Nous reprendrons contact avec vous rapidement.</p>

    <button type="button" class="btn-clean validate" (click)="closeDialog()">Fermer</button>
  </ng-container>
</ng-template>
