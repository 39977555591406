import { Observable } from 'rxjs';

export const fromIntersectionObserver = (
  element: HTMLElement,
  config?: IntersectionObserverInit
): Observable<IntersectionObserverEntry> =>
  new Observable<IntersectionObserverEntry>((subscriber) => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        subscriber.next(entry);
      });
    }, config);

    intersectionObserver.observe(element);

    return {
      unsubscribe() {
        intersectionObserver.disconnect();
      },
    };
  });
