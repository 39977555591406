import { APP_BASE_HREF, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Inject, LOCALE_ID, NgModule, NgZone, Optional, PLATFORM_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CollectivityInterface } from '@b2b/models';
import { CheckmarkLoaderModule } from '@commons/checkmark-loader/checkmark-loader.component';
import { FooterModule } from '@commons/footer/footer.component';
import { HeaderModule } from '@commons/header/header.component';
import { ScrollTopModule } from '@commons/scroll-top/scroll-top.component';
import { ToasterModule } from '@commons/toaster/toaster.component';
import { ngxsConfig } from '@config/ngxs.config';
import { routerConfig } from '@config/router.config';
import { trackingConfig } from '@config/tracking-config';
import { ContactUsComponent } from '@core/components/contact-us/contact-us.component';
import { CoreComponent } from '@core/components/core/core.component';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { JwtService } from '@core/services/jwt.service';
import { CustomMetaService } from '@core/services/meta-service/meta.service';
import { getCollectivityDomain, provideCollectivityFactory } from '@core/utils/collectivity-utils';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SharedModule } from '@shared/shared.module';
import { CitiesState } from '@stores/cities/cities.state';
import { CollectivityState } from '@stores/collectivity/collectivity.state';
import { SetConsents } from '@stores/consents/consents.actions';
import { ConsentsState } from '@stores/consents/consents.state';
import { JwtState } from '@stores/jwt/jwt.state';
import { ProfileState } from '@stores/profile/profile.state';
import { UserState } from '@stores/user/user.state';
import { COLLECTIVITY_WEBSERVICES_TOKEN } from '@webservices/injection-tokens';
import { PoleEmploiInterceptor } from '@webservices/jobs/pole-emploi.interceptor';
import { ALGOLIA_SERVICE_TOKEN, AlgoliaParamsInterface } from '@wizbii/algolia';
import { DataStorageService, IMAGINARY_PIPE_CONFIG, ImageService, WINDOW_PROVIDERS } from '@wizbii/angular-utilities';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii/jwt';
import { LocaleEnum } from '@wizbii/models';
import { setupTracking, track } from '@wizbii/tracking';
import { ACCOUNT_API_CONFIG, AccountWebservice, AlgoliaWebservice, CONTENT_API_CONFIG } from '@wizbii/webservices';
import { Request } from 'express';
import { from } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';

const algoliaParams: AlgoliaParamsInterface = {
  applicationId: environment.algolia.applicationId,
  apiKey: environment.algolia.apiKey,
  prefix: environment.isDeployed ? environment.platform : 'staging',
  options: {},
};

@NgModule({
  declarations: [CoreComponent, ContactUsComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: environment.applicationId }),
    SharedModule,
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    HttpClientModule,
    MatNativeDateModule,
    ScrollToModule.forRoot(),
    ToasterModule,
    FeaturesModule,
    HeaderModule,
    FooterModule,
    QuicklinkSharedModule,
    RouterModule.forRoot([], routerConfig),
    NgxsModule.forRoot([CollectivityState, JwtState, ConsentsState, CitiesState, UserState, ProfileState], ngxsConfig),
    NgxsRouterPluginModule.forRoot(),
    CheckmarkLoaderModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    ScrollTopModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    DataStorageService,
    WINDOW_PROVIDERS,
    {
      provide: ALGOLIA_SERVICE_TOKEN,
      useValue: algoliaParams,
    },
    {
      provide: COLLECTIVITY_WEBSERVICES_TOKEN,
      useFactory: provideCollectivityFactory((collectivity) => {
        return {
          collectivityDomain: getCollectivityDomain(collectivity),
        };
      }),
    },
    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },
    { provide: LOCALE_ID, useValue: LocaleEnum.fr_FR },
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PoleEmploiInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', hideRequiredMarker: true, floatLabel: 'never' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: IMAGINARY_PIPE_CONFIG,
      useValue: {
        imaginaryUrl: environment.api.imaginary,
      },
    },
    {
      provide: CONTENT_API_CONFIG,
      useValue: {
        baseUrl: environment.api.content,
        locale: environment.i18n.locale,
      },
    },
    AlgoliaWebservice,
    {
      provide: ACCOUNT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.account,
        locale: environment.i18n.locale,
        baseUrlHost: environment.api.account,
      },
    },
    AccountWebservice,
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {
  // eslint-disable-next-line  max-params
  constructor(
    matIconRegistry: MatIconRegistry,
    router: Router,
    store: Store,
    domSanitizer: DomSanitizer,
    metaService: CustomMetaService,
    ngZone: NgZone,
    @Inject(PLATFORM_ID) platformId: string,
    @Optional() @Inject(REQUEST) request?: Request,
    @Optional() @Inject('serverUrl') protected serverUrl?: string
  ) {
    ngZone.runOutsideAngular(() => {
      metaService.initSeo((window as any).collectivity);

      const iconsSetsUrls = [];
      const collectivity = (window as any).collectivity as CollectivityInterface;
      const domain = environment.isDeployed
        ? collectivity.url
        : serverUrl && isPlatformServer(platformId)
        ? `${serverUrl}/`
        : '/';

      if (environment.platform === 'local') {
        iconsSetsUrls.push(
          `${domain}assets/commons.svg`,
          ...Object.keys(collectivity.configuration.assets.sprites).map((name) => `${domain}assets/${name}.svg`)
        );
      } else {
        iconsSetsUrls.push(
          `${domain}assets/commons.svg`,
          ...Object.keys(collectivity.configuration.assets.sprites).map((name) => `${domain}_internal/sprite/${name}`)
        );
      }

      iconsSetsUrls.forEach((iconsSetUrl) => {
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl(iconsSetUrl));
      });

      if (isPlatformBrowser(platformId)) {
        this.initializeTracking(router, store, metaService, request);
      }
    });
  }

  private initializeTracking(router: Router, store: Store, metaService: CustomMetaService, request?: Request) {
    setupTracking(trackingConfig((window as any).collectivity)).then(() => {
      let previousUrl: URL;

      from(window.WizbiiGdpr.getConsents())
        .pipe(
          tap((consents: any) => store.dispatch(new SetConsents(consents))),
          switchMap(() => router.events),
          filter((event) => event instanceof NavigationEnd),
          map((event) => (event as NavigationEnd).urlAfterRedirects),
          startWith(window.location.href.replace(window.origin, '')),
          map((page) => ({ url: window.location.href, page }))
        )
        .subscribe({
          next: (event: any) => {
            const url = new URL(event.url);

            // send pageview only for pages without queryParams
            if (previousUrl?.hash !== url.hash || previousUrl?.pathname !== url.pathname) {
              track('pageview', event);
              previousUrl = url;
            }
            const ogUrl = request
              ? `${request.protocol}//${request.hostname}${request.path}`
              : `${window.location.origin}${window.location.pathname}`;
            metaService.updateOrAddTag({
              property: 'og:url',
              content: ogUrl,
            });
            const canonicalUrl = request
              ? `${request.protocol}//${request.hostname}${request.url}`
              : `${window.location.origin}${window.location.pathname}${window.location.search}`;
            metaService.updateOrAddCanonicalURL(canonicalUrl);
          },
        });
    });
  }
}
