import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { JwtTokens } from '@wizbii/jwt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationWebService {
  private readonly baseUrl: string;
  private readonly appId: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = environment.api.authentication;
    this.appId = environment.applicationId;
  }

  signIn(username: string, password: string, platformId: string): Observable<JwtTokens> {
    return this.http.post<JwtTokens>(`${this.baseUrl}/v2/auth/fr_FR`, {
      username,
      password,
      platform: `b2b-collectivity-${platformId}`,
      appId: this.appId,
    });
  }

  fromLoginToken(loginToken: string, platformId: string): Observable<JwtTokens> {
    return this.http.post<JwtTokens>(`${this.baseUrl}/v1/auth/login-token`, {
      loginToken,
      appId: this.appId,
      platform: `b2b-collectivity-${platformId}`,
    });
  }

  fromRefreshToken(tokens: JwtTokens, platformId: string): Observable<JwtTokens> {
    return this.http
      .post<JwtTokens>(`${this.baseUrl}/v1/jwt/refresh`, {
        appId: this.appId,
        ...tokens,
        platform: `b2b-collectivity-${platformId}`,
      })
      .pipe(map((response) => ({ token: response['token'], refreshToken: response['refreshToken'] })));
  }

  sendPasswordResetToken(email: string, collectivityId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/v1/user/lost-password`, {
      email,
      appId: this.appId,
      platform: `b2b-collectivity-${collectivityId}`,
      collectivityId,
    });
  }

  resetPassword(credentials: { passwordToken: string; password: string }): Observable<JwtTokens> {
    return this.http
      .post<JwtTokens>(`${this.baseUrl}/v1/user/lost-password/${credentials.passwordToken}`, {
        appId: this.appId,
        password: credentials.password,
      })
      .pipe(map((response) => ({ token: response['token'], refreshToken: response['refreshToken'] })));
  }

  updateEmail(id: string, email: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/v1/user/${id}/email`, { email, appId: this.appId });
  }

  updatePassword(credentials: {
    userId: string;
    oldPassword: string;
    newPassword: string;
  }): Observable<{ id: string; username: string; uniqUserId: string }> {
    return this.http
      .put<{ username: string; uniqUserId: string }>(`${this.baseUrl}/v1/user/${credentials.userId}/password`, {
        appId: this.appId,
        oldPassword: credentials.oldPassword,
        newPassword: credentials.newPassword,
      })
      .pipe(
        map((response) => ({
          id: credentials.userId,
          username: response['username'],
          uniqUserId: response['uniqUserId'],
        }))
      );
  }
}
