import { CommonModule, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollTopComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() scrollOffset = -300;
  @Input() scrollDestination = document.getElementsByTagName('main')[0];
  // if container isn't given, the default container is <main> tag
  @Input() container?: string;

  @ViewChild('scrollTop') scrollTop!: ElementRef;

  focusable$ = new BehaviorSubject<boolean>(false);
  containerBounding$ = new BehaviorSubject<DOMRect | undefined>(undefined);
  containerBoundingStaticTop$ = new BehaviorSubject<number | undefined>(undefined);
  scrollTopStaticBottom$ = new BehaviorSubject<number>(0);

  scrollSectionStyle = {};

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
  ) {}

  @HostListener('window:scroll')
  @HostListener('window:resize')
  onScroll(): void {
    this.calculateScrollTopPosition();
  }

  ngOnInit(): void {
    this.scrollSectionStyle = {
      opacity: '0',
    };
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      if (isPlatformServer(this.platformId)) {
        return;
      }
      this.container
        ? this.containerBounding$.next(document.getElementById(this.container)?.getBoundingClientRect())
        : this.containerBounding$.next(document.getElementsByTagName('main')[0]?.getBoundingClientRect());
      this.container &&
        this.containerBoundingStaticTop$.next(document.getElementById(this.container)?.getBoundingClientRect().top);
      this.scrollTopStaticBottom$.next(
        (this.containerBoundingStaticTop$.value as number) +
          (this.scrollTop?.nativeElement.getBoundingClientRect().height as number)
      );

      this.calculateScrollTopPosition();
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  applyStyle(): void {
    if (!this.container) {
      // if <main> top is higher than viewport
      if (
        this.containerBounding$.value &&
        this.containerBounding$.value.top < 0 &&
        this.containerBounding$.value.bottom >= window.innerHeight
      ) {
        this.scrollSectionStyle = {
          opacity: `${this.containerBounding$.value && this.containerBounding$.value.top > 0 ? '0' : '1'}`,
          right: `-${(this.scrollTop.nativeElement.getBoundingClientRect().height / 2 - 30) / 16}rem`,
          bottom: '0',
        };
      }
      // if <main> bottom is higher than viewport
      else if (this.containerBounding$.value && this.containerBounding$?.value?.bottom < window.innerHeight) {
        this.scrollSectionStyle = {
          position: 'absolute',
          right: `-${(this.scrollTop.nativeElement.getBoundingClientRect().height / 2 - 30) / 16}rem`,
          bottom: '0',
        };
      }
      //else
      else {
        this.scrollSectionStyle = {
          opacity: '0',
          bottom: '-999rem',
        };
      }
    } else {
      // if scrollTop's bottom is higher than article bottom
      if (
        window.scrollY +
          this.scrollTopStaticBottom$.value -
          this.scrollTop?.nativeElement.getBoundingClientRect().width <
        (this.containerBoundingStaticTop$.value || 0) + (this.containerBounding$.value?.height || 0)
      ) {
        this.scrollSectionStyle = {
          opacity: `${this.containerBounding$.value && this.containerBounding$.value.top > 0 ? '0' : '1'}`,
          left: `${this.containerBounding$.value && this.containerBounding$.value.right / 16}rem`,
          top: `${
            (((this.containerBoundingStaticTop$.value as number) || 0) +
              (this.scrollTop.nativeElement.getBoundingClientRect().width as number)) /
            16
          }rem`,
        };
        // else position "absolute" depending on container (which must be position "relative")
      } else {
        this.scrollSectionStyle = {
          position: 'absolute',
          top: `${
            ((this.containerBounding$.value?.height || 0) -
              this.scrollTop.nativeElement.getBoundingClientRect().height +
              2 * this.scrollTop.nativeElement.getBoundingClientRect().width) /
            16
          }rem`,
          right: `-${this.scrollTop.nativeElement.getBoundingClientRect().height / 16}rem`,
        };
      }
    }
  }

  calculateScrollTopPosition(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.container
      ? this.containerBounding$.next(document.getElementById(this.container)?.getBoundingClientRect())
      : this.containerBounding$.next(document.getElementsByTagName('main')[0]?.getBoundingClientRect());
    if (this.containerBounding$.value) {
      this.focusable$.next(this.containerBounding$.value.top < 0);
    }

    this.applyStyle();

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.focusable$.complete();
    this.containerBounding$.complete();
    this.containerBoundingStaticTop$.complete();
    this.scrollTopStaticBottom$.complete();
  }
}

@NgModule({
  imports: [CommonModule, ScrollToModule, MatIconModule],
  declarations: [ScrollTopComponent],
  exports: [ScrollTopComponent],
})
export class ScrollTopModule {}
