<aside class="scroll-section" [ngStyle]="scrollSectionStyle" #scrollTop>
  <button
    class="btn-round--white scroll-section__button"
    aria-label="Retourner en haut de la page"
    [ngxScrollTo]="scrollDestination"
    [ngxScrollToOffset]="scrollOffset"
    [tabindex]="(focusable$ | async) ? '0' : '-1'"
  >
    <mat-icon svgIcon="arrow_right" role="presentation" class="scroll-section__button__icon"></mat-icon>
  </button>
  <p class="scroll-section__text">Retourner en haut de la page</p>
</aside>
