import { Injectable } from '@angular/core';
import { City } from '@b2b/models';
import { NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';

export type CitiesStateModel = City[] | null;
const CitiesStateToken = new StateToken<CitiesStateModel>('cities');

@State<CitiesStateModel>({
  name: CitiesStateToken,
  defaults: null,
})
@Injectable()
export class CitiesState implements NgxsOnInit {
  @Selector([CitiesState])
  static cities(state: CitiesStateModel): CitiesStateModel {
    return [...(state ?? [])].sort((a, b) => (a.city < b.city ? -1 : a.city > b.city ? 1 : 0));
  }

  ngxsOnInit(context: StateContext<CitiesStateModel>): void {
    context.setState((window as any).cities);
  }
}
