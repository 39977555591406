<div
  class="toaster"
  [style.background-color]="(component$ | async)?.backgroundColor"
  [@animateToasts]
  *ngIf="component$ | async as component"
>
  <ng-container *ngIf="component.toDisplay as toDisplay">
    <ng-template
      *ngIf="(toDisplay | isComponentPortal) === true; else stringMessage"
      [cdkPortalOutlet]="toDisplay | castToComponentPortal"
    ></ng-template>
    <ng-template #stringMessage
      ><p>{{ toDisplay }}</p></ng-template
    >
  </ng-container>
</div>
