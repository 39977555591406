import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreModule } from '@core/core.module';
import { environment } from '@environment';
import { LocaleEnum } from '@wizbii/models';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeFr, LocaleEnum.fr_FR);

const startModule = () =>
  platformBrowserDynamic()
    .bootstrapModule(CoreModule)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });

if ((module as any)['hot']) {
  startModule();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    startModule();
  });
}
