import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { CommonListEvent } from '@models/common-event';
import { ImageService } from '@wizbii/angular-utilities';

@Component({
  selector: 'app-event-calendar-card',
  templateUrl: './event-calendar-card.component.html',
  styleUrls: ['./event-calendar-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCalendarCardComponent {
  @Input() event!: CommonListEvent;
  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;

  /* eslint-disable no-useless-escape */
  readonly uuidRegexp = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;

  constructor(readonly router: Router, readonly route: ActivatedRoute, private readonly imageService: ImageService) {}

  get imgSrc(): string {
    const url = this.event.thumbnail ?? this.getImgUrl(this.event);
    return this.imageService.resize(url, { width: 130, type: 'auto' });
  }

  private getImgUrl(event: CommonListEvent): string {
    const baseUrl =
      event.thumbnail && this.uuidRegexp.test(event.thumbnail)
        ? `${environment.api.googleStorage}/${environment.wizbiiFiles}`
        : `${environment.wizbiiImages}/events`;

    return event.thumbnail
      ? `${baseUrl}/${event.thumbnail}`
      : `${environment.wizbiiImages}/events/${event.type}/${Math.floor(Math.random() * 5)}.jpg`;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [EventCalendarCardComponent],
  exports: [EventCalendarCardComponent],
})
export class EventCalendarCardModule {}
